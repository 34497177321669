import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { publicRouteLink } from "../constant/Routes";
import axios from "axios";
import { toast } from "react-hot-toast";
import { MainContext } from "../context/main";
import { useNavigate } from "react-router-dom";

const OtpVerification = ({ onClose }) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [isValid, setIsValid] = useState(true);
  const {
    countryCode,
    mob,
    buttonValue,
    setMob,
    setCountryCode,
    setSubscriptionPlan,
    handleFormData,
    email,
    signUpType,
    setSignUpType,
  } = useContext(MainContext);
  const [disabled, setDisabled] = useState(false);
  const [timer, setTimer] = useState(60);
  const navigate = useNavigate();

  useEffect(() => {
    if (!mob || !countryCode) {
      if (!email) {
        toast.error("Unauthorized access. Redirecting...");
        navigate(publicRouteLink.SIGNIN); // Redirect to SignIn page
      }
    }
  }, [mob, countryCode, navigate]);

  useEffect(() => {
    return () => {
      setMob(null);
      setCountryCode(null);
    };
  }, [setMob, setCountryCode]);

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setDisabled(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (!mob || !countryCode) {
      if (!email) {
        navigate(publicRouteLink.SIGNIN, { replace: true }); // Redirect to SignIn page
      }
    }
  
    // Prevent back navigation
    const preventBack = () => {
      window.history.pushState(null, null, window.location.href);
    };
    preventBack();
    window.addEventListener("popstate", preventBack);
  
    return () => {
      window.removeEventListener("popstate", preventBack);
    };
  }, [mob, countryCode, navigate, email]);

  const handleOtpChange = (index, value) => {
    if (isNaN(value)) {
      return;
    }
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < 3) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const fetchSubscriptionPlan = async () => {
    const token = sessionStorage.getItem("Token");
    if (token) {
      try {
        const response = await axios.get(
          `https://haartistaloud-backend.kollywoodhungama.com/v1/checkSubscriptionStatus/${token}`
        );
        if (response.data?.status === 200) {
          const plan = response.data?.data[0]?.plan_name?.toLowerCase();
          localStorage.setItem("subscriptionPlan", plan); // Save plan in local storage
          setSubscriptionPlan(plan); // Save plan in context
        }
      } catch (error) {
        console.error("Error fetching subscription plan:", error);
      }
    }
  };

  const verifyOTP = async () => {
    if (otp.some((digit) => digit.trim() === "")) {
      toast.error("Please fill all the OTP fields.");
      return;
    }

    try {
      const response = await axios.post(
        "https://haartistaloud-backend.kollywoodhungama.com/v2/verify-otp",
        {
          mob: signUpType === "phone" ? mob : "",
          otp: otp.join(""),
          countryCode: countryCode,
          email: signUpType === "email" ? email : "",
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.data.result?.statusCode === 200) {
        sessionStorage.setItem("Token", response.data.token);
        toast.success("OTP Verified!");

        // Fetch subscription plan
        const fetchPlanPromise = fetchSubscriptionPlan();

        // Fetch user details
        const token = response.data.token;
        const fetchUserPromise = axios
          .get(
            `https://haartistaloud-backend.kollywoodhungama.com/v1/userById/${token}`
          )
          .then((userResponse) => {
            const userData = userResponse.data?.data[0];
            if (userData) {
              const updatedFormData = {
                country_code: userData.country_code || "",
                countryid: userData.country_code || "",
                fullName: userData.full_name || "",
                phone: userData.mobile || "",
                email: userData.email || "",
                country: userData.country || "",
                state: userData.state || "",
                city: userData.city || "",
                zipCode: userData.zipcode || "",
                instaLink: userData.instagram || "",
                fbLink: userData.facebook || "",
                youtubeLink: userData.youtube || "",
                twitterLink: userData.twitter || "",
              };

              // Update the form data in context using handleFormData
              handleFormData(updatedFormData);

              // Also persist the form data in localStorage
              localStorage.setItem("formData", JSON.stringify(updatedFormData));

              console.log("Updated and persisted formData:", updatedFormData);
            }
          })
          .catch((userError) => {
            console.error("Error fetching user details:", userError.message);
            // toast.error("Failed to fetch user details.");
          });

        // Wait for both promises to complete
        await Promise.all([fetchPlanPromise, fetchUserPromise]);

        // Redirect based on buttonValue
        if (buttonValue === "SignIn") {
          navigate(publicRouteLink.DASHBOARD);
        } else {
          navigate(publicRouteLink.COMPLETE_YOUR_ACCOUNT);
        }
      } else {
        toast.error(response.data.result.message || "OTP verification failed.");
      }
    } catch (error) {
      console.error("Error during OTP verification:", error.response || error);
      toast.error("Failed to verify OTP. Please try again.");
    }
  };

  useEffect(() => {
    console.log("Updated formData state:", handleFormData);
    console.log("signUpType:", signUpType);
  }, [handleFormData]);

  const sendOTP = async () => {
    try {
      let requestData;
      if (signUpType === "email") {
        requestData = {
          email: email,
          mob: "",
          countryCode: countryCode,
        };
      } else {
        requestData = {
          email: "",
          mob: mob,
          countryCode: countryCode.startsWith("+")
            ? countryCode
            : "+" + countryCode, // Ensure countryCode always starts with "+"
        };
      }
      const resp = await axios({
        url: "https://haartistaloud-backend.kollywoodhungama.com/v2/send-otp",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        // data: {
        //   mob: mob,
        //   countryCode: "" + countryCode,
        // },
        data: requestData,
      });

      if (resp.data?.statusCode === 200) {
        toast.success("OTP sent successfully!");
        setTimer(60);
        setDisabled(true);
      } else {
        toast.error(
          resp.data?.message || "Failed to send OTP. Please try again!"
        );
      }
    } catch (error) {
      console.log("Error from sendOTP function:", error.response || error);
      toast.error("Error sending OTP. Please try again later.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center py-16">
      <div className="flex bg-white rounded-[4%]">
        <div className="w-80 mt-9 ml-10">
          <h2 className="text-3xl font-semibold mb-3 text-[#FF6C2F]">
            Enter verification code
          </h2>
          <p className="text-[#181818] text-sm font-medium mb-7 break-words">
            We have just sent a verification code to{" "}
            {signUpType === "phone"
              ? `${
                  countryCode.includes("+") ? countryCode : `+${countryCode}`
                }${mob}`
              : `${email}`}
          </p>
          <div className="flex mb-7">
            {otp.map((digit, index) => (
              <input
                key={index}
                id={`otp-input-${index}`}
                className="w-9 h-12 text-center bg-[#E6E6E6] text-[#FF6C2F] text-xl font-medium border border-black border-solid rounded-md mx-2"
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
              />
            ))}
          </div>

          {!isValid && (
            <p className="text-[#FA3235]">
              The security code is invalid or expired.
            </p>
          )}

          <div className="flex items-center">
            <button
              onClick={sendOTP}
              disabled={disabled}
              className={`text-[#2EB4FF] text-sm font-normal px-2 mb-7 ${
                disabled ? "cursor-not-allowed" : "hover:bg-white"
              }`}
            >
              Send the code again
            </button>
            {disabled && (
              <span style={{ color: "#FF6C2F" }}> (00:{timer}) </span>
            )}
          </div>
          {signUpType === "phone" ? (
            <Link to={publicRouteLink.CHANGE_PHONENUMBER}>
              <button className="text-[#2EB4FF] text-sm font-normal px-2 mb-7">
                Change phone number
              </button>
            </Link>
          ) : null}

          <br />
          <button
            onClick={verifyOTP}
            className="bg-[#FF6C2F] text-white text-sm font-normal px-4 py-2 mb-12 rounded-md w-80 h-12 "
          >
            Verify
          </button>
        </div>

        <div className="ml-16">
          <img
            src="assets/verification-pic.png"
            alt="verification-pic"
            className="h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;
