import React, { useState, useEffect } from "react";

export default function TakeDownPopup({
  isOpen,
  onClose,
  songDetails,
  contentIdForSong,
}) {
  const [selectedSong, setSelectedSong] = useState(false);
  const [reason, setReason] = useState("");
  const [details, setDetails] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [emailId, setEmailId] = useState("");
  const [showPleaseWait, setShowPleaseWait] = useState(false);

  const handleReasonChange = (event) => setReason(event.target.value);
  const handleDetailsChange = (event) => setDetails(event.target.value);

  useEffect(() => {
    console.log("Received songDetails:", songDetails); // Debugging
    console.log("Received contentIdForSong:", contentIdForSong);
  }, [songDetails, contentIdForSong]);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = sessionStorage.getItem("Token");
      console.log("Token from sessionStorage:", token);
  
      if (!token) {
        console.error("No token found in sessionStorage.");
        return;
      }
  
      try {
        const response = await fetch(
          `https://haartistaloud-backend.kollywoodhungama.com/v1/userById/${token}`
        );
  
        console.log("Response status:", response.status);

        const text = await response.text();
        console.log("Raw response text:", text);
  
        if (!response.ok) throw new Error(`Failed to fetch user info: ${response.status}`);
  
        const result = JSON.parse(text);
        console.log("Parsed API Response:", result);
  
        const userData = Array.isArray(result.data) ? result.data[0] : result.data;
        setUserName(userData?.full_name || "");
        setUserId(userData?.user_id || "");
        setEmailId(userData?.email || "");
        console.log("User Data in TakeDown:", userData);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };
  
    fetchUserData();
  }, []);
  
  
  

  const updateInfo = async () => {
    const content_id = contentIdForSong;
    const apiUrl = `https://haartistaloud-backend.kollywoodhungama.com/v2/contentTakedown/${content_id}`;

    const requestBody = {
      userName: userName,
      userId: userId,
      takeDownSong: songDetails,
      reasonForTakedown: reason,
      additionalDetails: details,
      emailId: emailId,
    };

    setShowPleaseWait(true);
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Takedown request placed successfully:", data);
        setShowPleaseWait(false);
        alert("Takedown request placed successfully!");
        handleOk();
        window.location.reload();
      } else {
        console.error("Takedown request failed!");
        setShowPleaseWait(false);
        alert("Takedown request failed!");
      }
    } catch (error) {
      console.error("Error updating Takedown request", error);
      setShowPleaseWait(false);
      alert("An error occurred while processing your request.");
    }
  };

  const handleOk = () => {
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#252525] w-[90%] sm:w-[40%] rounded-lg p-6 shadow-lg">
        <h2 className="text-white text-xl font-semibold mb-4">
          Take Down Song
        </h2>
        <div className="flex items-center space-x-4 mb-4">
          <input
            type="checkbox"
            id="songCheckbox"
            className="w-4 h-4 text-white border-gray-300 rounded"
            checked={selectedSong}
            onChange={() => setSelectedSong(!selectedSong)}
          />
          <label htmlFor="songCheckbox" className="text-white">
            {songDetails || "Song Name"}
          </label>
        </div>
        <div className="mb-4">
          <label htmlFor="reason" className="block text-white font-medium mb-2">
            Reason for Takedown
          </label>
          <select
            id="reason"
            className="w-full text-white bg-[#252525] p-2 border border-gray-300 rounded-lg"
            value={reason}
            onChange={handleReasonChange}
          >
            <option value="">Select a reason</option>
            <option value="Rights disputes or ownership conflicts">
              Rights disputes or ownership conflicts
            </option>
            <option value="Expired or terminated contracts">
              Expired or terminated contracts
            </option>
            <option value="Desire to update or improve song quality">
              Desire to update or improve song quality
            </option>
            <option value="Rebranding or change in artist identity">
              Rebranding or change in artist identity
            </option>
            <option value="Platform exclusivity agreements">
              Platform exclusivity agreements
            </option>
            <option value="Legal claims or copyright infringement issues">
              Legal claims or copyright infringement issues
            </option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="details"
            className="block text-white font-medium mb-2"
          >
            Additional Details
          </label>
          <textarea
            id="details"
            rows="3"
            className="w-full p-2 border border-gray-300 rounded-lg bg-[#252525] text-white"
            placeholder="Explain your reason..."
            value={details}
            onChange={handleDetailsChange}
          ></textarea>
        </div>
        <div className="flex justify-end space-x-4">
          <button
            className="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-[#FF6C2F] text-white rounded-lg cursor-pointer hover:bg-[#FF6C2F] disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={updateInfo}
            disabled={!selectedSong || !reason}
          >
            OK
          </button>
        </div>
      </div>
      {showPleaseWait && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90">
          <div className="bg-[#171717] p-8 rounded-md text-white w-96 text-center">
            <div className="flex items-center justify-center mb-4">
              <svg
                className="w-8 h-8 text-white animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                ></path>
              </svg>
            </div>
            <h2 className="text-xl">Please wait...</h2>
          </div>
        </div>
      )}
    </div>
  );
}
