import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";

export const MainContext = createContext();

const MainContextProvider = ({ children }) => {
  const [countryCode, setCountryCode] = useState("");
  const [mob, setMob] = useState("");
  const [email, setEmail] = useState("");
  const [formData, setFormData] = useState({
    country_code: "",
    countryid: "",
    stateid: "",
    fullName: "",
    phone: "",
    email: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    instaLink: "",
    fbLink: "",
    youtubeLink: "",
    twitterLink: "",
  });
  const [buttonName, setButtonName] = useState("Default Button");
  const [buttonValue, setButtonValue] = useState("Default Value");
  const [signUpType, setSignUpType] = useState("");
  const [isLanding, setisLanding] = useState(true);
  const [isAuthenticated, setisAuthenticated] = useState(false);
  const [contentType, setContentType] = useState("audio");
  const [file, setFile] = useState(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [planName, setPlanName] = useState(null);
  const [showIprs, setShowIprs] = useState(true);

  // Load formData from local storage on initialization
  useEffect(() => {
    const savedFormData = localStorage.getItem("formData");
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  }, []);

  // Save formData to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const updateButtonInfo = (name, value) => {
    setButtonName(name);
    setButtonValue(value);
  };

  const handleFormData = (e) => {
    if (e?.target) {
      // Check if it's an event object
      const { name, value } = e.target;

      if (name === "country_code") {
        let normalizedValue = value;
        if (normalizedValue.startsWith("++")) {
          normalizedValue = "+" + normalizedValue.slice(2);
        } else if (!normalizedValue.startsWith("+")) {
          normalizedValue = "+" + normalizedValue;
        }

        setFormData((prevData) => ({
          ...prevData,
          [name]: normalizedValue,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (typeof e === "object") {
      // If it's an object like updatedFormData
      setFormData((prevData) => ({
        ...prevData,
        ...e, // Merge the updated form data
      }));
    }
  };

  // const fetchSubscriptionPlan = async () => {
  //   const token = sessionStorage.getItem("Token");
  //   if (token) {
  //     try {
  //       const response = await axios.get(
  //         `https://haartistaloud-backend.kollywoodhungama.com/v1/checkSubscriptionStatus/${token}`
  //       );
  //       if (response.data?.status === 200) {
  //         const plan = response.data?.data[0]?.plan_name?.toLowerCase();
  //         localStorage.setItem("subscriptionPlan", plan); // Save plan in local storage
  //         setSubscriptionPlan(plan); // Save plan in context
  //         setPlanName(plan);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching subscription plan:", error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   fetchSubscriptionPlan();
  // }, []);

  // code to check is Iprs member
  const checkUserContent = async () => {
    const token = sessionStorage.getItem("Token");
    try {
      const response = await fetch(
        `https://haartistaloud-backend.kollywoodhungama.com/v1/checkExistingUser/${token}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user content status");
      }

      const data = await response.json();

      if (data?.data?.contentCount === 0) {
        setShowIprs(true); // Show IPRS if content count is 0
      } else {
        setShowIprs(false); // Hide IPRS if content count is greater than 0
      }
    } catch (error) {
      console.error("Error checking user content status:", error);
      setShowIprs(true); // Default to showing IPRS in case of error
    }
  };

  useEffect(() => {
    // console.log("Checking user content status...",showIprs);
    checkUserContent();
  }, []);

  return (
    <MainContext.Provider
      value={{
        countryCode,
        setCountryCode,
        mob,
        setMob,
        formData,
        handleFormData,
        buttonName,
        buttonValue,
        updateButtonInfo,
        isLanding,
        setisLanding,
        isAuthenticated,
        setisAuthenticated,
        contentType,
        setContentType,
        file,
        setFile,
        subscriptionPlan,
        setSubscriptionPlan,
        showIprs,
        email,
        setEmail,
        signUpType,
        setSignUpType,
        // planName,
        // setPlanName,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export const useContent = () => {
  return useContext(MainContext);
};

export default MainContextProvider;
