import React, { useState, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useNavigate } from "react-router-dom";
import { useContent } from "../context/main.js";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import axios from "axios";

export default function Iprs() {
  const { contentType } = useContent();
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const today = new Date();
  const day = today.getDate();
  const month = today.toLocaleString("en-GB", { month: "short" });
  const year = today.getFullYear().toString().slice(-2);
  const formattedDate = `${day} ${month} ${year}`;
  const [pdfdataSuccess, setPdfdataSuccess] = useState(false);
  const [hideContract, setHideContract] = useState(true);
  const sigCanvas = useRef({});
  const [signatureIprs, setSignatureIprs] = useState(null);
  const [ispdfSaved, setIspdfSaved] = useState(false);

  const navigate = useNavigate();

  const formDataLocal = JSON.parse(localStorage.getItem('formData'));

// Access the artistNameValue
const artistName = formDataLocal?.artistNameValue;

  const handleYesClick = () => {
    saveIprsResponseToLocalStorage("Yes");
    navigate("/attach-audio");
  };

  const handleNoClick = () => {
    saveIprsResponseToLocalStorage("No");
    setHideContract(false);
  };

  const saveIprsResponseToLocalStorage = (response) => {
    const formData = JSON.parse(localStorage.getItem("formData")) || {};
    formData.isIprsMember = response; // Store the IPRS response
    localStorage.setItem("formData", JSON.stringify(formData));
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
    setSignatureIprs("");
    localStorage.removeItem("signatureIprs");
  };

  const saveSignature = () => {
    if (sigCanvas.current.isEmpty()) {
      toast.error("Please provide your digital signature before agreeing.");
      return;
    }
    const dataUrl = sigCanvas.current.toDataURL("image/png"); //base64-encoded image
    console.log("Captured Signature Data URL:", dataUrl);
    setSignatureIprs(dataUrl);
    localStorage.setItem("signatureIprs", dataUrl);
    toast.success("Signature saved");
    // generatePDF();
  };

  // const handleSubmitClick = () => {
  //   if (sigCanvas.current.isEmpty()) {
  //     alert("Please provide your digital signature before agreeing.");
  //   } else {
  //     const dataUrl = sigCanvas.current.toDataURL("image/png"); //base64-encoded image
  //     setSignatureIprs(dataUrl);
  //     localStorage.setItem("signatureIprs", dataUrl);
  //     console.log(dataUrl);
  //     toast.success("Signature saved");
  //     navigate("/attach-audio");
  //   }
  // };
  const handleSubmitClick = async () => {
    if (!userInfo.signature) {
      if (sigCanvas.current.isEmpty()) {
        toast.error("Please provide your digital signature before agreeing.");
        return;
      }
    }
    if(!artistName)
      {
      toast.error("Please provide artist name before proceeding.");
      return;
    }
    await generatePDF();

    const uploadSuccess = await uploadPDF();

    if (uploadSuccess) {
      setPdfdataSuccess(true);
      // navigate("/attach-audio");
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    // Set the border color
    const borderColor = [0, 0, 0]; // Black border
    const content = document.querySelector(".iprs-agreement");
    let currentYPosition = 20;
    const leftMargin = 15;
    const rightMargin = 15;

    // Add a border to the first page
    doc.setDrawColor(borderColor[0], borderColor[1], borderColor[2]);
    doc.rect(
      10,
      10,
      doc.internal.pageSize.width - 20,
      doc.internal.pageSize.height - 20
    );

    // Set font
    doc.setFont("Helvetica");
    doc.setFontSize(12);

    // Wrap and add text
    const lines = doc.splitTextToSize(
      content.innerText,
      doc.internal.pageSize.width - leftMargin - rightMargin
    );
    const pageHeight = doc.internal.pageSize.height;
    const lineHeight = 5;
    const maxTextHeight = pageHeight - 40;

    lines.forEach((line) => {
      if (currentYPosition + lineHeight > maxTextHeight) {
        doc.addPage();
        currentYPosition = 20;
        doc.rect(
          10,
          10,
          doc.internal.pageSize.width - 20,
          doc.internal.pageSize.height - 20
        );
      }
      doc.text(line, leftMargin, currentYPosition);
      currentYPosition += lineHeight;
    });

    // Add the signature if available
    if (signatureIprs) {
      const img = new Image();
      img.onload = () => {
        const imageYPosition = currentYPosition + 10;
        const imageWidth = 100;
        const imageHeight = 50;

        doc.setFillColor(255, 255, 255);
        doc.rect(leftMargin, imageYPosition, imageWidth, imageHeight, "F");
        doc.setDrawColor(borderColor[0], borderColor[1], borderColor[2]);
        doc.rect(leftMargin, imageYPosition, imageWidth, imageHeight);
        doc.addImage(
          img,
          "PNG",
          leftMargin,
          imageYPosition,
          imageWidth,
          imageHeight
        );

        // Save the PDF after adding the signature
        saveIprsPDFToLocalStorage(doc, "IprsAgreement.pdf");
      };
      img.onerror = (err) => {
        console.error("Error loading signature image:", err);
      };
      img.src = signatureIprs; // Set the image source to the base64 string
    } else {
      console.warn("No signature provided");
    }
  };

  const saveIprsPDFToLocalStorage = (doc, key) => {
    const pdfData = doc.output("datauristring"); // Get the base64 data URI
    localStorage.setItem(key, pdfData);
    console.log(`PDF saved to localStorage with key: ${key}`);
    setTimeout(() => {
      setIspdfSaved(true);
    }, 2000);
  };

  let uploadedFilesHashes = new Set();
  const base64ToBlob = (base64, contentType = "application/pdf") => {
    let byteCharacters = atob(base64.split(",")[1]);

    const byteNumbers = new Array(byteCharacters.length)
      .fill(0)
      .map((_, i) => byteCharacters.charCodeAt(i));

    const blob = new Blob([new Uint8Array(byteNumbers)], { type: contentType });

    byteNumbers.length = 0; // Clear the byteNumbers array
    byteCharacters = null; // Optionally clear the byteCharacters string

    return blob;
  };

  const hashBase64 = (base64) => {
    // You can implement a proper hash here, for example using MD5 or SHA256
    // This is a placeholder for demonstration purposes
    return btoa(base64);
  };

  const uploadPDF = async () => {
    const token = sessionStorage.getItem("Token");
    // Retrieve PDFs from localStorage
    const iprsPDF = localStorage.getItem("IprsAgreement.pdf");

    // Check if PDFs exist in localStorage
    if (!iprsPDF) {
      toast.info("Please wait and click the 'Submit' button again.");
      // console.error("PDF is missing in localStorage.");
      return false;
    }

    // Hash and check if the files are already uploaded
    const IprsHash = hashBase64(iprsPDF);

    if (uploadedFilesHashes.has(IprsHash)) {
      toast.info("Please wait and click the 'Submit' button again.");
      // toast.warn("These files have already been uploaded.");
      return false;
    }

    const uniqueSuffix = Date.now(); // Use current timestamp
    const iprsFilename = `iprs_${uniqueSuffix}.pdf`;
    const iprsBlob = base64ToBlob(iprsPDF, "application/pdf");

    const contentId = localStorage.getItem("contentId");

    // Create FormData for the POST request
    const formData = new FormData();
    formData.append("contentId", contentId);
    formData.append("iprspdf", iprsBlob, iprsFilename);

    // API call
    try {
      const response = await fetch(
        `https://haartistaloud-backend.kollywoodhungama.com/v2/iprsPDF`,
        {
          method: "PUT",
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("PDF uploaded successfully!");

        const blobDownload = (blob, fileName) => {
          const blobURL = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobURL;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(blobURL);
        };

        // blobDownload(iprsBlob, iprsFilename);

        // Clear PDFs from localStorage after successful upload
        localStorage.removeItem("IprsAgreement.pdf");
        console.log("PDFs cleared from localStorage.");
        toast.success("You're all set!");
        return true;
      } else {
        const errorData = await response.json();
        console.error("Failed to upload PDFs:", errorData);
        return false;
      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const token = sessionStorage.getItem("Token");
      try {
        const response = await axios.get(
          `https://haartistaloud-backend.kollywoodhungama.com/v1/T&CData/${token}`
        );

        if (response.data.status === 200) {
          const userData = response.data.data[0];
          setUserInfo(userData);

          const signatureUrl = userData.signature.replace(
            "https://storage.cloud.google.com",
            "https://storage.googleapis.com"
          );

          // console.log("Fetching signature Blob from URL:", signatureUrl);

          if (signatureUrl) {
            const blobUrl = await fetchImageAsBlob(signatureUrl, true);
            // console.log("Blob URL:", blobUrl);
            setSignatureIprs(blobUrl);
            localStorage.setItem("signature", blobUrl);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const fetchImageAsBlob = async (url, enableCors = false) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        mode: enableCors ? "cors" : "no-cors", // Highlight: Use CORS mode
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch image. Status: ${response.status}`);
      }

      const blob = await response.blob();
      return URL.createObjectURL(blob); // Convert Blob to Object URL
    } catch (error) {
      console.error("Error fetching image as Blob:", error);
      throw error;
    }
  };

  const delayedNavigate = () => {
    setTimeout(() => {
      navigate("/attach-audio");
    }, 1000);
  };

  return (
    <div>
      <div className="flex">
        {contentType === "audio" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Audio</span>
          </h2>
        )}
        {contentType === "video" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Video</span>
          </h2>
        )}
      </div>
      <div
        className="container1"
        style={{ width: "auto", minHeight: "700px", height: "auto" }}
      >
        <div className="flex flex-col items-start profileBar w-[28%] bg-[#ffffff0d] rounded-md ">
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%] mt-[13%]">
            <p>Details</p>
          </div>
          <br />
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>Content Distribution</p>
          </div>
          <br />
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>T&C</p>
          </div>
          <br />
          <div className="text-[#FF6C2F] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>IPRS</p>
          </div>
          <br />
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            {contentType === "audio" && <p>Attach Audio</p>}
            {contentType === "video" && <p>Attach Video</p>}
          </div>
          <br />
          <div className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <p>Select Cover Image</p>
          </div>
        </div>
        <div className="formIprs flex flex-col mt-[5%] w-[70%]">
          {hideContract ? (
            <div className="pl-[100px]">
              <div>
                <h2
                  className="text-[#FFFFFF] text-[1.1rem] mb-[5%]"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Are you an IPRS member?
                </h2>
              </div>
              <div className="flex justify-start mt-[1%]">
                <button
                  className="w-[6rem] h-[3rem] bg-[#302F2F] rounded-md text-[#FFFFFF]  hover:bg-[#FF6C2F] active:bg-[#c75525]"
                  onClick={handleYesClick}
                >
                  Yes
                </button>
                <button
                  className="w-[6rem] h-[3rem] bg-[#302F2F] rounded-md text-[#FFFFFF] mx-[25px] hover:bg-[#484848] active:bg-[#484848]"
                  onClick={handleNoClick}
                >
                  No
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div
                className="iprs-agreement overflow-y-scroll termsContent h-[auto] p-[30px] bg-white rounded-md text-black"
                // style={{ border: "1px solid #FFFFFF", borderRadius: "5px" }}
              >
                <div className="flex justify-center">
                  <h2
                    className=" text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "500",
                    }}
                  >
                    ON THE LETTER HEAD OF MUSIC PROVIDER
                  </h2>
                  <br />
                  <br />
                </div>
                <div className="flex justify-end">
                  <h2
                    className=" text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    Date:{" "}
                    <span
                      className="text-[0.9rem]"
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "400",
                      }}
                    >
                      {formattedDate}
                    </span>
                  </h2>
                  <br />
                  <br />
                </div>
                <div>
                  <span
                    className="text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "400",
                    }}
                  >
                    To,
                  </span>
                  <br />
                  <br />
                  <div className="flex justify-start">
                    <p
                      className="text-[0.9rem]"
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "500",
                        width: "60%",
                      }}
                    >
                      Hungama Digital Media Entertainment Private Limited C-
                      Wing, 10th Floor, Times Square Building, Andheri Kurla
                      Road, Marol, Andheri (E), Mumbai 400059, Maharashtra,
                      India
                    </p>
                  </div>
                  <br />
                  <p
                    className="text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    Dear Sir,
                  </p>
                  <br />
                  <p
                    className="text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    This is in reference to the sound recordings (as defined
                    under the Copyright Act, 1957) owned and/or controlled by
                    us, the Exclusive and limited rights of which have been
                    licensed to you for exploitation on properties and services
                    owned by you (the “Content”).
                  </p>
                  <br />
                  <p
                    className="text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    We hereby confirm that the publishing rights in and to the
                    Content i.e., the rights in and to the underlying literary
                    and musical works and performances incorporated in the
                    Content are solely and exclusively owned by us.
                  </p>
                  <br />
                  <p
                    className="text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    We understand that as part of the properties and services
                    offered by you, you shall be utilizing both our sound
                    recordings as well as the underlying literary and musical
                    works and performances incorporated in such sound
                    recordings.
                  </p>
                  <br />
                  <p
                    className="text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    We have granted a valid and subsisting license in our sound
                    recording rights to Hungama Artist Aloud, a division of
                    Hungama Digital Media Entertainment Private Limited and/or
                    its group companies (collectively “Hungama”), as the case
                    maybe, for the purposes of exercising the rights licensed by
                    us to Hungama for the entire duration of the term of rights
                    licensed including any extensions and/or renewals thereof
                    (“Term”) on the properties and services offered by you.
                  </p>
                  <br />
                  <p
                    className="text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    During the interim period i.e., until we authorize IPRS to
                    administer publishing rights in and to the Content for and
                    on our behalf, we, as the owner of the sound recordings and
                    underlying musical and literary works and performances
                    incorporated in such Content, hereby authorize you to use
                    such underlying musical and literary works and performances
                    incorporated in such Content as part of the properties and
                    services offered by you.
                  </p>
                  <br />
                  <p
                    className="text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    We hereby agreed to indemnify you, your directors and
                    employees for any issue arising out of the usage of such
                    underlying works and performances.
                  </p>
                  <br />
                  <br />
                  <br />
                  <p
                    className="text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    Yours sincerely,
                  </p>
                  <br />
                  <p
                    className="text-[0.9rem]"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "300",
                    }}
                  >
                    For and on behalf of {artistName}
                  </p>
                  <br />
                  <br />
                </div>
              </div>
              {userInfo.signature ? (
                <div
                  className="sigImageContainer bg-white rounded-md my-6"
                  style={{ width: 500, height: 200 }}
                >
                  <img
                    src={userInfo.signature}
                    alt="Signature"
                    className="signatureImage"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              ) : (
                <div className="m-[auto] mt-[4%] pl-[5%]">
                  <h2 className="text-[#FFFFFF] text-[1rem] mb-[10px]">
                    Digital Signature:
                  </h2>
                  <SignatureCanvas
                    ref={sigCanvas}
                    canvasProps={{
                      width: 500,
                      height: 200,
                      backgroundColor: "white",
                      className: "sigCanvas",
                    }}
                    penColor="blue"
                  />

                  <div className="flex mt-[10px]">
                    <button
                      className="text-[#FFFFFF] w-[6rem] h-[2rem] bg-[#FF6C2F] rounded-[5px]"
                      onClick={clearSignature}
                    >
                      Clear
                    </button>
                    <button
                      className="text-[#FFFFFF] w-[6rem] h-[2rem] bg-[#FF6C2F] rounded-[5px] ml-[10px]"
                      onClick={saveSignature}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}

              <div
                className="flex justify-end mb-[5%] mt-[5%] pr-[5%]"
                style={{ flexGrow: "8" }}
              >
                {!pdfdataSuccess ? (
                  <button
                    className="text-[#FFFFFF] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122]"
                    onClick={handleSubmitClick}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    className="text-[#FFFFFF] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122]"
                    onClick={delayedNavigate}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
