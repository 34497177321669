import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { publicRouteLink } from "../constant/Routes";
import SignUp1 from "./SignUp1";
import Popover from "@mui/material/Popover";
import SignIn from "./SignIn";
import Notifications from "./notifications";
import ProfileDropdown from "./profileDropdown";
import axios from "axios";

const LandingNavbar = () => {
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);
  const [anchorElSignup, setAnchorElSignup] = useState(null);
  const openSignup = Boolean(anchorElSignup);
  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const openNotification = Boolean(anchorElNotification);
  const openProfile = Boolean(anchorElProfile);
  const [notifications] = useState([" No notifications available."]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null); // Ref for detecting outside click
  const buttonRef = useRef(null);

  useEffect(() => {
    const token = sessionStorage.getItem("Token");

    if (token) {
      axios
        .get(
          `https://haartistaloud-backend.kollywoodhungama.com/v1/checkToken/${token}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            setIsTokenValid(true);
          } else {
            navigate(publicRouteLink.SIGNIN);
          }
        })
        .catch(() => {
          setIsTokenValid(false);
          navigate(publicRouteLink.SIGNIN);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
       if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setOpen(false); // Close menu only if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClickSignup = (event) => {
  //   setAnchorElSignup(event.currentTarget);
  // };

  const handleClick = (event) => {
    if (window.innerWidth < 768) {
      navigate(publicRouteLink.SIGNIN);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClickSignup = (event) => {
    if (window.innerWidth < 768) {
      navigate(publicRouteLink.SIGNUP);
    } else {
      setAnchorElSignup(event.currentTarget);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setAnchorElSignup(null);
    setAnchorElProfile(null);
  };

  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };

  const handleClickNotification = (event) => {
    setAnchorElNotification(
      (prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget) // Toggle visibility
    );
  };

  const handleClickProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  if (loading) return null; // Avoid rendering while checking the token

  return (
    <>
      <div className="shadow-md w-full sticky top-0 left-0 z-10">
        <div className="md:flex items-center justify-between bg-black text-white py-2 md:px-10 px-6">
          <div className="font-bold text-2xl cursor-pointer flex items-center font-[Poppins] text-gray-800">
            <span className="mr-1">
              <a href={publicRouteLink.HOME}>
                <img src="\assets\HungamaLogo.png" alt="hungama-logo" />
              </a>
            </span>
          </div>

          <div
            ref={buttonRef}
            onClick={() => setOpen((prev) => !prev)}
            className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
          >
            <ion-icon name={open ? "close" : "menu"}></ion-icon>
          </div>

          <ul
            ref={menuRef}
            className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-black text-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
              open ? "top-20 " : "top-[-490px]"
            }`}
          >
{/* ticketing button commented from here-------------------------------------------*/}
            {/* Updated "Concert Tickets" Link with Dropdown */}
            {/* <li className="relative md:ml-0 text-base font-medium md:my-0 my-7">
              <button
                className="flex items-center px-3 py-0.5 border-2 border-orange-500 rounded-md hover:bg-orange-500 hover:text-black transition-all"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)} // Toggle dropdown
              >
                <span className="mr-2">Concert Tickets</span>
                <ion-icon
                  name={isDropdownOpen ? "chevron-up" : "chevron-down"}
                  className="" // Space between text and icon
                ></ion-icon>
              </button> */}

              {/* {isDropdownOpen && (
                <div className="absolute left-0 mt-2 w-60 bg-black border border-gray-700 rounded-md shadow-lg">
                  <Link
                    to={publicRouteLink.INDEPENDENT_TOGETHER}
                    className="block px-4 py-2 text-white hover:bg-orange-600 rounded-t-md"
                  >
                    Independent Together
                  </Link> */}
                  {/* <Link
                    to="/event2"
                    className="block px-4 py-2 text-white hover:bg-orange-600"
                  >
                    Event 1
                  </Link>
                  <Link
                    to="/event3"
                    className="block px-4 py-2 text-white hover:bg-orange-600 rounded-b-md"
                  >
                    Event 2
                  </Link> */}
                {/* </div> */}
              {/* )}
            </li> */}

{/* to here-------------------------------------------*/}
            {[
              // {
              //   name: "Concert Tickets",
              //   link: publicRouteLink.INDEPENDENT_TOGETHER,
              // },
              {
                name: "Marketing Plans",
                link: publicRouteLink.MARKETING_PLANS,
              },
              { name: "Uploads", link: publicRouteLink.UPLOADS },
              { name: "Contact Us", link: publicRouteLink.CONTACT_US },
              { name: "About Us", link: publicRouteLink.ABOUT_US },
            ].map((link) => (
              <li
                key={link.name}
                className={`md:ml-8 text-base font-medium md:my-0 my-7 ${
                  link.name === "Concert Tickets" ? "md:ml-0" : ""
                }`}
              >
                {link.name === "Concert Tickets" ? (
                  <Link
                    to={link.link}
                    className="px-3 py-0.5 border-2 border-orange-500 rounded-md hover:bg-orange-500 hover:text-black transition-all"
                  >
                    {link.name}
                  </Link>
                ) : (
                  <Link to={link.link}>{link.name}</Link>
                )}
              </li>
            ))}

            {isTokenValid ? (
              <>
                {/* Notification bell */}
                <div className="md:ml-5 w-70 p-1 bg-black text-white overflow-hidden inline-flex justify-center items-center">
                  <div>
                    <button onClick={handleClickNotification} className="w-6">
                      <img src="assets/Doorbell.png" alt="notification-icon" />
                      <Popover
                        open={openNotification}
                        anchorEl={anchorElNotification}
                        onClose={handleCloseNotification}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        sx={{ marginTop: "2%" }}
                      >
                        <Notifications notifications={notifications} />
                      </Popover>
                    </button>
                  </div>
                </div>

                {/* Profile dropdown */}
                <div className="md:ml-2 p-1 bg-black text-[#FEC961] inline-flex items-center">
                  <button onClick={handleClickProfile} className="w-6">
                    <ion-icon
                      style={{ fontSize: "2.2rem" }}
                      name="person-circle-sharp"
                    ></ion-icon>
                  </button>
                  <Popover
                    open={openProfile}
                    anchorEl={anchorElProfile}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <ProfileDropdown />
                  </Popover>
                </div>
              </>
            ) : (
              <div className="w-64 flex overflow-hidden justify-center items-center rounded-3xl bg-white md:ml-8 text-base font-medium">
                <button
                  onClick={handleClick}
                  className="w-36 text-black bg-white rounded-3xl h-12"
                >
                  Sign In
                </button>
                <Popover
                  open={open1}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{ marginTop: "2%" }}
                >
                  <SignIn onClose={handleClose} />
                </Popover>

                <button
                  onClick={handleClickSignup}
                  className="w-36 text-black bg-[#FEC961] rounded-3xl h-12"
                >
                  Sign Up
                </button>
                <Popover
                  open={openSignup}
                  anchorEl={anchorElSignup}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{ marginTop: "2%" }}
                >
                  <SignUp1 onClose={handleClose} />
                </Popover>
              </div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default LandingNavbar;
