import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { SearchIcon } from '@heroicons/react/outline';
import TakeDownPopup from "./TakeDownPopup";
import DashboardPopup from "./DashboardPopup";

const Releases = () => {
  const [tdata, setTdata] = useState([]);
  const [isTakeDownPopupOpen, setIsTakeDownPopupOpen] = useState(false);
  const [isDashboardPopupOpen, setIsDashboardPopupOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [statusIsOpen, setStatusIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSong, setSelectedSong] = useState("");
  const [contentIdForSong, setContentIdForSong] = useState("");
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const statusToggleDropdown = () => {
    setStatusIsOpen(!statusIsOpen);
  };
  const handleNewReleaseClick = () => {
    navigate("/upload");
  };

  const fetchData = async () => {
    const token = sessionStorage.getItem("Token"); // Get the token from sessionStorage
    try {
      setIsLoading(true); // Set loading state to true
      const response = await fetch(
        `https://haartistaloud-backend.kollywoodhungama.com/v1/getAudioVideo/${token}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data"); // Handle failed fetch
      }

      const data = await response.json(); // Parse the JSON data
      console.log("Fetched Data:", data); // Log the fetched data (optional for debugging)

      // Check if the response contains valid data
      if (data && data.status === 200 && Array.isArray(data.data)) {
        setTdata(data.data); // Set the fetched data into the state
      } else {
        throw new Error("No valid data received"); // Handle invalid response
      }
    } catch (err) {
      setError(err.message); // Set error message in state if fetch fails
    } finally {
      setIsLoading(false); // Set loading state to false after the fetch operation
    }
  };

  // Call fetchData when component mounts
  useEffect(() => {
    fetchData();
  }, []);

  const filteredData = Array.isArray(tdata)
    ? tdata.filter((item) => {
        if (!item.contentName || !item.releaseDate || !item.status) {
          return false;
        }

        const typeMatch =
          selectedType === "All" ||
          (item.type && item.type.toLowerCase() === selectedType.toLowerCase());
        const statusMatch =
          selectedStatus === "All" ||
          (item.status &&
            item.status.toLowerCase() === selectedStatus.toLowerCase());
        const searchMatch = item.contentName
          .toLowerCase()
          .includes(searchTerm.toLowerCase()); // Match search term

        return typeMatch && statusMatch && searchMatch;
      })
    : [];

  const handleTakedownClick = (contentId, contentName) => {
    setSelectedSong(contentName);
    setContentIdForSong(contentId);
    setIsTakeDownPopupOpen(true); // Open the popup
  };

  const handleEditClick = (contentId) => {
    setContentIdForSong(contentId);
    setIsDashboardPopupOpen(true);
  };

  return (
    <>
      <div className="px-12 py-20">
        <p className="text-white text-2xl font-medium mb-3">
          Your <span className="text-[#FF6C2F]">Releases</span>
        </p>
        <div className="flex items-center">
          <input
            type="text"
            placeholder="Search your releases..."
            onChange={(e) => setSearchTerm(e.target.value)}
            className="px-4 py-2 bg-[#D9D9D9] w-96 rounded-l-md focus:outline-none "
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-10 h-10 p-2 rounded-r-md bg-[#D9D9D9]"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
            />
          </svg>

          <button
            className="flex items-center ml-auto bg-[#FF6C2F] text-black rounded p-2 gap-1"
            onClick={handleNewReleaseClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            New Release
          </button>
        </div>

        <table className="table-auto w-full justify-between sm:justify-center text-[0.9rem] text-white mt-12">
          <thead className="">
            <tr className="bg-[#E8E8E8] text-black text-left h-11">
              <th className="px-4 rounded-tl-md rounded-bl-md w-1/3">
                Content Name
              </th>
              <th className="w-1/4">
                <button onClick={toggleDropdown} className="flex items-center">
                  Type
                  <svg
                    className="w-4 h-4 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>
                {isOpen && (
                  <div className="absolute mt-3 w-24 text-center bg-[#252525] rounded-md shadow-sm">
                    <ul className="flex flex-col space-y-2 p-2">
                      <li
                        className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                        onClick={() => {
                          setSelectedType("All");
                          toggleDropdown();
                        }}
                      >
                        All
                      </li>
                      <li
                        className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                        onClick={() => {
                          setSelectedType("Audio");
                          toggleDropdown();
                        }}
                      >
                        Audio
                      </li>
                      <li
                        className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                        onClick={() => {
                          setSelectedType("Video");
                          toggleDropdown();
                        }}
                      >
                        Video
                      </li>
                    </ul>
                  </div>
                )}
              </th>
              <th className="w-1/4">Release Date</th>
              <th className="w-1/4">
                <button
                  onClick={statusToggleDropdown}
                  className="flex items-center"
                >
                  Status
                  <svg
                    className="w-4 h-4 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>
                {statusIsOpen && (
                  <div className="absolute mt-3 w-32 text-center bg-[#252525] rounded-md shadow-sm">
                    <ul className="flex flex-col space-y-2 p-2">
                      <li
                        className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                        onClick={() => {
                          setSelectedStatus("All");
                          statusToggleDropdown();
                        }}
                      >
                        All
                      </li>
                      <li
                        className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                        onClick={() => {
                          setSelectedStatus("Approved");
                          statusToggleDropdown();
                        }}
                      >
                        Approved
                      </li>
                      <li
                        className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                        onClick={() => {
                          setSelectedStatus("Disapproved");
                          statusToggleDropdown();
                        }}
                      >
                        Disapproved
                      </li>
                      <li
                        className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                        onClick={() => {
                          setSelectedStatus("Live");
                          statusToggleDropdown();
                        }}
                      >
                        Live
                      </li>
                      <li
                        className="bg-[#313131] text-white hover:bg-[#FF6C2F] font-light"
                        onClick={() => {
                          setSelectedStatus("New");
                          statusToggleDropdown();
                        }}
                      >
                        New
                      </li>
                    </ul>
                  </div>
                )}
              </th>
              <th className="rounded-tr-md rounded-br-md w-1/4 pr-4">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.filter((item) => item.status !== "Takedown").map((item) => (
              <tr key={item.contentId} className="">
                <td className="py-2 pl-4">{item.contentName}</td>
                <td className="py-2">{item.type}</td>
                <td className="py-2">{formatDate(item.releaseDate)}</td>
                <td className="py-2">
                  {(item.status === "Live" || item.status === "Approved") && (
                    <div className="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="#2fd100"
                        className="w-6 h-6"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      {item.status}
                    </div>
                  )}
                  {item.status === "New" && (
                    <div className="flex items-center gap-2">
                      <img src="assets\Edit File.svg" alt="edit-icon" />
                      {item.status}
                    </div>
                  )}
                  {item.status === "Disapproved" && (
                    <div className="flex items-center gap-2">
                      <img src="assets\Box Important.svg" alt="reject-icon" />
                      {item.status}
                    </div>
                  )}
                </td>
                <td className="py-2">
                  {item.status === "Live" && (
                    <button
                      className="px-3 py-1 bg-[#800000] rounded-md"
                      onClick={() =>
                        handleTakedownClick(item.contentId, item.contentName)
                      }
                    >
                      Takedown
                    </button>
                  )}
                   {/* commented Request temporary */}

                  {/* {item.status === "Approved" && (
                    <button
                      className="px-5 py-1 bg-[#228B22] rounded-md"
                      onClick={() => handleEditClick(item.contentId)}
                    >
                      Request
                    </button>
                  )} */}
                  {(item.status === "Disapproved" || item.status === "New" || item.status === "Approved") && (
                    <button
                      className="px-8 py-1 bg-[#49606f] rounded-md"
                      onClick={() => handleEditClick(item.contentId)}
                    >
                      Edit
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <TakeDownPopup
          isOpen={isTakeDownPopupOpen}
          onClose={() => setIsTakeDownPopupOpen(false)}
          songDetails={selectedSong}
          contentIdForSong={contentIdForSong}
        />
        <DashboardPopup
          isOpen={isDashboardPopupOpen}
          onClose={() => setIsDashboardPopupOpen(false)}
          contentIdForSong={contentIdForSong}
        />
      </div>
    </>
  );
};

export default Releases;
