import { set } from "date-fns";
import React, { useState, useEffect } from "react";

export default function Popup({
  isOpen,
  onClose,
  contentIdForSong,
  contentType,
}) {
  const [selectedSubGenres, setSelectedSubGenres] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedMood, setSelectedMood] = useState("");
  const [selectedRating, setSelectedRating] = useState("");
  const [lyricist, setLyricist] = useState("");
  const [musicDirector, setMusicDirector] = useState("");
  const [singer, setSinger] = useState("");
  const [producer, setProducer] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [trackName, setTrackName] = useState("");
  const [showPleaseWait, setShowPleaseWait] = useState(false);

  useEffect(() => {
    console.log("Received contentIdForSong in edit popup:", contentIdForSong);
    console.log("Received contentType in edit popup:", contentType);
    console.log("Popup isOpen:", isOpen);
  }, [isOpen,contentIdForSong, contentType]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedMood("");
      setSelectedRating("");
      setLyricist("");
      setMusicDirector("");
      setSinger("");
      setProducer("");
      setDescription("");
      setTags("");
      setTrackName("");
    }
  }, [isOpen]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching data for contentId:", contentIdForSong);
        if (!isOpen || !contentIdForSong) {
          console.log("Skipping fetch: Missing contentId or popup is closed");
          return;
        }
        const response = await fetch(
          `https://haartistaloud-backend.kollywoodhungama.com/v1/contentInfo/${contentIdForSong}`
        );
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        setSelectedMood(result.data.mood || "");
        setSelectedRating(result.data.rating || "");
        setLyricist(result.data.writer || "");
        setMusicDirector(result.data.director || "");
        setSinger(result.data.singer || "");
        setProducer(result.data.licensor || "");
        setDescription(result.data.content_description || "");
        setTags(result.data.tags || "");
        setTrackName(result.data.track_name || "");
        console.log("Fetched content info:", result);
      } catch (error) {
        console.error("Error fetching content info:", error);
      }
    };
    fetchData();
  }, [isOpen, contentIdForSong]);

  const handleOk = () => {
    onClose();
  };
  if (!isOpen) return null;

  const handleLanguageToggle = (language) => {
    setSelectedLanguages((prev) => {
      const newLanguages = prev.includes(language)
        ? prev.filter((item) => item !== language)
        : [...prev, language];
      //   saveToLocalStorage(newLanguages);
      return newLanguages;
    });
  };

  const handleGenreToggle = (genre) => {
    setSelectedGenres((prev) => {
      const newGenres = prev.includes(genre)
        ? prev.filter((item) => item !== genre)
        : [...prev, genre];
      //   saveToLocalStorage(newGenres);
      return newGenres;
    });
  };

  const handleSubGenreToggle = (subGenre) => {
    setSelectedSubGenres((prev) => {
      const newSubGenres = prev.includes(subGenre)
        ? prev.filter((item) => item !== subGenre)
        : [...prev, subGenre];
      //   saveToLocalStorage(newSubGenres);
      return newSubGenres;
    });
  };

  const handleKeywordToggle = (keyword) => {
    setSelectedKeywords((prev) => {
      const newKeyword = prev.includes(keyword)
        ? prev.filter((item) => item !== keyword)
        : [...prev, keyword];
      //   saveToLocalStorage(newKeyword);
      return newKeyword;
    });
  };

  const handleMoodChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedMood(selectedValue);
  };

  const handleRatingChange = (event) => {
    const selectedRatingValue = event.target.value;
    setSelectedRating(selectedRatingValue);
  };

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const updateInfo = async () => {
    if (
      !selectedLanguages.length ||
      !selectedSubGenres.length ||
      !selectedGenres.length
    ) {
      alert("Please select Language, Genre, and Sub-Genre.");
      return; // Stop execution if validation fails
    }
    if (contentType === "video" && !selectedKeywords.length) {
      alert("Please select Keyword for video.");
      return; // Stop execution if validation fails
    }
    const content_id = contentIdForSong;
    const apiUrl = `https://artistaloud-backend.kollywoodhungama.com/v1/editInfo/${content_id}`;

    const requestBody = {
      language: selectedLanguages.join(", "), // Convert array to string
      genre: selectedGenres.join(", "),
      subGenre: selectedSubGenres.join(", "),
      mood: selectedMood,
      lyricist: lyricist, // ✅ Passing state value
      musicDirector: musicDirector, // ✅ Passing state value
      singer: singer, // ✅ Passing state value
      tags: tags, // ✅ Passing state value
      keywords: selectedKeywords.join(", "),
      trackName: trackName, // ✅ Passing state value
      producer: producer, // ✅ Passing state value
      description: description, // ✅ Passing state value
      rating: selectedRating, // ✅ Passing state value
    };

    setShowPleaseWait(true);
    try {
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Update successful:", data);
        setShowPleaseWait(false);
        alert("Updated successfully!");
        handleOk();
        window.location.reload();
      } else {
        console.error("Failed to update info");
        setShowPleaseWait(false);
        alert("Failed to update info!");
      }
    } catch (error) {
      console.error("Error updating info:", error);
      setShowPleaseWait(false);
      alert("An error occurred while updating.");
    }
  };

  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center bg-[#161616] bg-opacity-70">
        <div className="bg-[#555555] p-4 rounded-md w-full max-w-screen-lg h-[60vh] overflow-x-auto">
          <table className="w-full min-w-max bg-gray-700 text-white border border-gray-600 rounded-md">
            <thead className="bg-[#252525]">
              <tr>
                <th className="w-[180px] autnh-1 rounded-l-md border-r border-gray-600">
                  LANGUAGE
                </th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">
                  GENRE
                </th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">
                  SUB GENRE
                </th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">
                  MOOD
                </th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">
                  LYRICIST
                </th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">
                  MUSIC DIRECTOR
                </th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">
                  SINGER
                </th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">
                  PRODUCER
                </th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">
                  DESCRIPTION
                </th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">
                  TAGS
                </th>
                {/* <th className="w-[180px] autnh-1 border-r border-gray-600">
                  COPY RIGHTS
                </th>
                <th className="w-[180px] autnh-1 border-r border-gray-600">
                  PUBLISHING RIGHTS
                </th> */}
                {contentType === "video" && (
                  <th className="w-[220px] autnh-1 border-r border-gray-600">
                    KEYWORDS
                  </th>
                )}
                {contentType === "video" && (
                  <th className="w-[180px] autnh-1 border-r border-gray-600">
                    RATINGS
                  </th>
                )}
                {/* )} */}
                <th className="w-[180px] autnh-1 rounded-r-md">TRACK NAME</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-0.5 bg-transparent"></tr>
            </tbody>
            <tbody>
              <tr>
                <td className="autnr rounded-l-md border-r border-gray-600">
                  <div style={{ maxHeight: "180px", overflowY: "auto" }}>
                    {[
                      "Hindi",
                      "Telugu",
                      "Tamil",
                      "Bengali",
                      "Gujarati",
                      "Malayalam",
                      "Odia",
                      "Urdu",
                      "Kannada",
                      "Sanskrit",
                      "Marathi",
                      "Assamese",
                      "Punjabi",
                      "Nepali",
                      "Konkani",
                      "Maithili",
                      "Dogri",
                      "Sindhi",
                      "Santali",
                      "Manipuri",
                      "Bhojpuri",
                      "Arabic",
                      "Meitei",
                      "Chattisgiri",
                      "Haryanvi",
                    ].map((language) => (
                      <div key={language} className="flex items-center">
                        <input
                          type="checkbox"
                          id={language}
                          value={language}
                          checked={selectedLanguages.includes(language)}
                          onChange={() => handleLanguageToggle(language)}
                          className="mr-2"
                        />
                        <label htmlFor={language}>{language}</label>
                      </div>
                    ))}
                  </div>
                </td>
                <td className="autnr border-r border-gray-600">
                  <div style={{ maxHeight: "180px", overflowY: "auto" }}>
                    {[
                      "Acoustic",
                      "Anthem",
                      "Bhajan",
                      "Bhangra",
                      "Bharatanatyam",
                      "Bhavageete",
                      "Blues",
                      "Carnatic music",
                      "Chaiti",
                      "Classical",
                      "Comedy",
                      "Contemperory",
                      "Country",
                      "Dadra",
                      "Devotional",
                      "Devotional song",
                      "Dhamar",
                      "Dhrupad",
                      "EDM",
                      "Filmi",
                      "Filmi qawwali",
                      "Folk music",
                      "Funk",
                      "Fusion",
                      "Gaana",
                      "Ghazal",
                      "Hard Rock",
                      "Haveli Sangeet",
                      "Hindi cinema",
                      "Hindustani classical music",
                      "Hip-Hop",
                      "Hori",
                      "Indian hip hop",
                      "Indian pop",
                      "Indian rock",
                      "Inspirational",
                      "Khyal",
                      "Light music",
                      "Mand",
                      "Music in ancient India",
                      "Music of Punjab",
                      "Music of Rajasthan",
                      "Odissi music",
                      "Patriotic",
                      "Poetry",
                      "Pop",
                      "Qawwali",
                      "R&B",
                      "Rabindra Sangeet",
                      "Raga",
                      "Raga rock",
                      "Rap",
                      "Rock",
                      "Romantic",
                      "Sadra",
                      "Semi-classical music",
                      "Sufi",
                      "Sufi music",
                      "Sufi rock",
                      "Sugama Sangeetha",
                      "Tappa",
                      "Tarana",
                      "Thumri",
                      "Trance",
                      "Trap",
                      "World music",
                    ].map((genre) => (
                      <div key={genre} className="flex items-center">
                        <input
                          type="checkbox"
                          id={genre}
                          value={genre}
                          checked={selectedGenres.includes(genre)}
                          onChange={() => handleGenreToggle(genre)}
                          className="mr-2"
                        />
                        <label htmlFor={genre}>{genre}</label>
                      </div>
                    ))}
                  </div>
                </td>
                <td className="autnr border-r border-gray-600">
                  <div style={{ maxHeight: "180px", overflowY: "auto" }}>
                    {[
                      "Aarti",
                      "Abhang",
                      "Acoustic",
                      "Acoustic Pop",
                      "Alternative",
                      "Alternative Metal",
                      "Alternative Rock",
                      "Anthem",
                      "Ashaar",
                      "Ballad",
                      "Bhajan",
                      "Bhakti Sangeet",
                      "Bheem Geet",
                      "Blues",
                      "Bollywood",
                      "Carnatic",
                      "Carols",
                      "Chants",
                      "Children",
                      "Christian",
                      "Classic Rock",
                      "Classical",
                      "Comedy",
                      "Contemporary",
                      "Country",
                      "Dance",
                      "Dance Pop",
                      "Devotional",
                      "Dharmik Sangeet",
                      "Disco",
                      "Discourse",
                      "Dohe",
                      "Dua",
                      "Dubstep",
                      "Easy Listening",
                      "EDM",
                      "Electro",
                      "Electro House",
                      "Electronic Trap",
                      "Electronica",
                      "Experimental",
                      "Festival",
                      "Folk",
                      "Folk Rock",
                      "Funk",
                      "Funny",
                      "Fusion",
                      "Ghazal",
                      "Gospel",
                      "Grunge",
                      "Guru Bani",
                      "Hadith Sharif",
                      "Hamd",
                      "Hard Rock",
                      "Hindustani",
                      "Hip hop",
                      "Hollywood",
                      "House",
                      "Hymns",
                      "Indie",
                      "Indie Pop",
                      "Indie Rock",
                      "Inspirational",
                      "Interview",
                      "Islamic",
                      "Jazz",
                      "Kirtan",
                      "Lavani",
                      "Lokgeet",
                      "Lounge",
                      "Mantras",
                      "Meditation",
                      "Mersia",
                      "Mixtape",
                      "Motivational",
                      "Movie Soundtracks",
                      "Mushaira",
                      "Naad",
                      "Naats",
                      "Narration",
                      "Natak",
                      "New Age",
                      "Nonstop",
                      "Novelty",
                      "Oldies",
                      "Patriotic",
                      "Peaceful",
                      "Pop",
                      "Qawwali",
                      "Rap",
                      "Reggae",
                      "Remix",
                      "Rock",
                      "Romantic",
                      "Sanskrit",
                      "Sufi",
                      "Sound Effects",
                      "Speech",
                      "Spiritual",
                      "Sports",
                      "Storytelling",
                      "Talas",
                      "Talk",
                      "Techno",
                      "Themed Music",
                      "Traditional",
                      "Trance",
                      "Vedic",
                      "Wedding",
                      "World",
                    ].map((subGenre) => (
                      <div key={subGenre} className="flex items-center">
                        <input
                          type="checkbox"
                          id={subGenre}
                          value={subGenre}
                          checked={selectedSubGenres.includes(subGenre)}
                          onChange={() => handleSubGenreToggle(subGenre)}
                          className="mr-2"
                        />
                        <label htmlFor={subGenre}>{subGenre}</label>
                      </div>
                    ))}
                  </div>
                </td>

                <td className="autnr border-r border-gray-600">
                  <select
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] p-[2%]"
                    style={{ outlineStyle: "none" }}
                    value={selectedMood}
                    onChange={handleMoodChange}
                  >
                    <option value="Happy">Happy</option>
                    <option value="Sad">Sad</option>
                    <option value="Exuberant">Exuberant</option>
                    <option value="Energetic">Energetic</option>
                    <option value="Frantic">Frantic</option>
                    <option value="Anxious">Anxious</option>
                    <option value="Calm">Calm</option>
                    <option value="Contentment">Contentment</option>
                  </select>
                </td>
                <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    value={lyricist}
                    onChange={handleInputChange(setLyricist)}
                    style={{ outlineStyle: "none" }}
                  />
                </td>
                <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    value={musicDirector}
                    onChange={handleInputChange(setMusicDirector)}
                    style={{ outlineStyle: "none" }}
                  />
                </td>
                <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    value={singer}
                    onChange={handleInputChange(setSinger)}
                    style={{ outlineStyle: "none" }}
                  />
                </td>
                <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    value={producer}
                    onChange={handleInputChange(setProducer)}
                    style={{ outlineStyle: "none" }}
                  />
                </td>
                <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    value={description}
                    onChange={handleInputChange(setDescription)}
                    style={{ outlineStyle: "none" }}
                  />
                </td>
                <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    value={tags}
                    onChange={handleInputChange(setTags)}
                    style={{ outlineStyle: "none" }}
                  />
                </td>
                {/* <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    name=""
                    id=""
                    style={{ outlineStyle: "none" }}
                  />
                </td>
                <td className="autnr border-r border-gray-600">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    name=""
                    id=""
                    style={{ outlineStyle: "none" }}
                  />
                </td> */}
                {contentType === "video" && (
                  <td className="autnr border-r border-gray-600">
                    <div style={{ maxHeight: "180px", overflowY: "auto" }}>
                      {[
                        "Themes and messages",
                        "Violence",
                        "Nudity",
                        "Sex",
                        "Language",
                        "Drug and substance abuse",
                        "Horror",
                      ].map((keyword) => (
                        <div key={keyword} className="flex items-center">
                          <input
                            type="checkbox"
                            id={keyword}
                            value={keyword}
                            checked={selectedKeywords.includes(keyword)}
                            onChange={() => handleKeywordToggle(keyword)}
                            className="mr-2"
                          />
                          <label htmlFor={keyword}>{keyword}</label>
                        </div>
                      ))}
                    </div>
                  </td>
                )}

                {contentType === "video" && (
                  <td className="autnr border-r border-gray-600">
                    <select
                      className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] p-[2%] pl-2"
                      style={{ outlineStyle: "none" }}
                      value={selectedRating}
                      onChange={handleRatingChange}
                    >
                      <option
                        className="hover:bg-[#FF6C2F] active:bg-[#c75525]"
                        value="U"
                      >
                        U
                      </option>
                      <option value="7+">7+</option>
                      <option value="13+">13+</option>
                      <option value="16+">16+</option>
                      <option value="18+">18+</option>
                    </select>
                  </td>
                )}

                <td className="autnr rounded-r-md">
                  <input
                    className="rounded-[5px] bg-[#4c4c4c] w-[8rem] h-8 mt-[1%] text-[#FFFFFF] font-[Poppins] pl-[6px]"
                    type="text"
                    value={trackName}
                    onChange={handleInputChange(setTrackName)}
                    style={{ outlineStyle: "none" }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="flex justify-end mt-[5vh]">
            <button
              className="bg-[#131313] text-[0.9rem] text-white rounded-md hover:bg-[#FF6C2F] active:bg-[#c75525] p-[12px]"
              onClick={updateInfo}
            >
              Update
            </button>
            <button
              className=" ml-[10px] bg-[#131313] text-[0.9rem] text-white rounded-md hover:bg-[#252525] active:bg-[#484848] p-[12px]"
              onClick={handleOk}
            >
              Close
            </button>
          </div>
        </div>
      </div>
      {showPleaseWait && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90">
          <div className="bg-[#171717] p-8 rounded-md text-white w-96 text-center">
            <div className="flex items-center justify-center mb-4">
              <svg
                className="w-8 h-8 text-white animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                ></path>
              </svg>
            </div>
            <h2 className="text-xl">Please wait...</h2>
          </div>
        </div>
      )}
    </div>
  );
}
