import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { publicRouteLink } from "../constant/Routes";
import { useState } from "react";

const LandingPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: 5000,
    fade: true,
    ease: "liner",
    pauseOnHover: false, // Prevents pause when the mouse hovers over the slider
    draggable: false,
  };

  const [data, setData] = useState([
    {
      id: 1,
      img: "assets/arman-malik.png",
      name: "Amaal Malik",
      bio: "Independent artists receive genuine support through Artist Aloud. Whenever I seek assistance, a real person promptly responds to my queries, ensuring that my concerns are addressed. I count on Artist Aloud to amplify my music's reach across the globe.",
    },
    {
      id: 2,
      img: "assets/mame-khan.png",
      name: "Mame Khan",
      bio: "Crafting music brings me immense fulfillment and purpose; it serves as my connection to the world. I feel fortunate to have an audience spanning the globe, and I take joy in knowing that my music resonates with them. Thanks to Hungama Artist Aloud, I can effortlessly share my music with this diverse audience.",
    },
    {
      id: 3,
      img: "assets/shekhar.png",
      name: "Shekhar Ravjiani",
      bio: "Support and reliability serve as the bedrock for an artist's ability to explore, evolve, and share their artistry with the community. In this regard, Artist Aloud's consistent support and revenue collection model have consistently met our expectations and facilitated our creative journey",
    },
    {
      id: 4,
      img: "assets/amiway.png",
      name: "Emiway Bantai",
      bio: "As a musician, my ultimate aspiration is to make my music accessible to people worldwide. It's the dream. Thanks to HAA, this dream effortlessly transforms into reality. Wherever I may journey, I'm confident that my songs precede me, reaching listeners across the globe.",
    },
  ]);

  const [bg, setBg] = useState([
    {
      id: 1,
      link: publicRouteLink.SIGNIN,
      img: "assets/bg-carousel-1.png",
      heading: "Create! Own! Publish!",
      text: "#BeOriginal #BeIndependent",
    },
    {
      id: 2,
      link: publicRouteLink.MARKETING_PLANS,
      img: "assets/bg-carousel-2.png",
      heading: "It's time to turn that dream into reality",
      text: "Grow your fans through our marketing services",
    },
  ]);

  const [showAll, setShowAll] = useState(false);

  return (
    <>
      <section className="relative overflow-hidden">
        <div className="overflow-hidden">
          <Slider {...settings}>
            {bg.map((item, index) => {
              // Manually insert a line break after the word 'dream' while keeping the word itself
              const headingParts = item.heading.split("dream");

              return (
                <div
                  key={index}
                  className="w-full h-screen flex flex-shrink-0 flex-grow-0"
                  style={{
                    backgroundImage: `url(${item.img})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <img
                    className="w-full h-full object-cover"
                    src={item.img}
                    alt={`bg-carousel-${index}`}
                  />
                  <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white font-poppins">
                    <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold">
                    {headingParts.length > 1 ? (
                        <>
                          {headingParts[0]}
                          <span className="block">dream{headingParts[1]}</span>
                        </>
                      ) : (
                        item.heading
                      )}
                    </h1>
                    <p className="text-lg md:text-xl font-bold mb-8">
                      {item.text}
                    </p>
                    <Link to={item.link}>
                      <button className="text-black no-underline bg-[#FEC961] w-52 h-14 rounded-full flex justify-center items-center">
                        <span className="font-bold">Get Started</span>
                      </button>
                    </Link>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>

      <section className="text-center pb-20 bg-black">
        <div className="text-5xl font-bold break-words text-white pt-4 pb-4">
          <h1>Learn more about us!</h1>
        </div>
        <br />

        <div className="space-y-12 px-4 md:px-8 lg:px-16 xl:px-36">
          <div className="flex flex-col justify-start md:flex-row md:space-x-4 lg:space-x-8">
            <div className="bg-[#FF6C2F] text-white w-full md:w-80 lg:w-96 h-20 rounded-full flex justify-center items-center">
              <span className="text-xl md:text-2xl font-medium break-words">
                Audio Distribution
              </span>
            </div>
            <div className="bg-white text-black w-full md:w-80 lg:w-96 h-20 rounded-full flex justify-center items-center mt-3 md:mt-0">
              <span className="text-xl md:text-2xl font-medium break-words">
                Video Distribution
              </span>
            </div>
            {/* <div className="bg-[#FF6C2F] text-white w-full md:w-96 lg:w-96 h-20 rounded-full flex justify-center items-center mt-3 md:mt-0">
              <span className="text-xl md:text-2xl font-medium break-words">
                Marketing Services
              </span>
            </div> */}
          </div>
          <div className="flex flex-col justify-end md:flex-row md:space-x-2 lg:space-x-6">
            <div className="bg-white text-black w-full md:w-80 lg:w-96 h-20 rounded-full flex justify-center items-center ">
              <span className="text-xl md:text-2xl font-medium break-words">
                Dashboard Access
              </span>
            </div>
            {/* <div className="bg-[#FF6C2F] text-white w-full md:w-80 lg:w-96 h-20 rounded-full flex justify-center items-center mt-3 md:mt-0">
              <span className="text-xl md:text-2xl font-medium break-words">
                Timely Royalty Payouts
              </span>
            </div> */}
            {/* <div className="bg-[#FF6C2F] text-white w-full md:w-80 lg:w-96 h-20 rounded-full flex justify-center items-center mt-3 md:mt-0">
              <span className="text-xl md:text-2xl font-medium break-words">
                Audio Distribution
              </span>
            </div> */}
            <div className="bg-[#FF6C2F] text-white w-full md:w-96 lg:w-[30rem] h-20 rounded-full flex justify-center items-center mt-3 md:mt-0">
              <span className="text-xl md:text-2xl font-medium break-words">
                Content Push & Placements
              </span>
            </div>
          </div>
        </div>

        <div className="px-4 md:px-5 lg:px-5 xl:px-10">
          <p className=" text-white text-2xl md:text-3xl lg:text-4xl font-normal break-words md:px-24 pt-8 md:pt-32 pb-10 md:pb-20 lg:pb-24">
            Get your music on Spotify, Apple Music, YouTube, JioSaavn and more,
            reaching listeners worldwide.
          </p>
          <div className="">
            <div className="grid grid-cols-2 md:flex md:flex-wrap justify-center items-center gap-4 px-1 md:px-12 pb-8 md:pb-0">
              <img
                src="assets/spotify-logo.png"
                alt="spotify-logo"
                className="w-24 sm:w-28 md:w-36 h-auto mx-auto"
              />
              <img
                src="assets/amazonmusic.png"
                alt="amazonmusic-logo"
                className="w-28 sm:w-28 md:w-40 h-auto mx-auto"
              />
              <img
                src="assets/ituneslogo.png"
                alt="itunes-logo"
                className="w-20 sm:w-28 md:w-32 h-auto mx-auto"
              />
              <img
                src="assets/jiosaavanlogo.png"
                alt="saven-logo"
                className="w-32 sm:w-32 md:w-48 h-auto mx-auto"
              />
              <img
                src="assets/youtube-music-white 1.png"
                alt="youtube-music"
                className="w-20 sm:w-28 md:w-28 h-auto mx-auto"
              />
              <img
                src="assets/youtubewhite.png"
                alt="youtube"
                className="w-28 sm:w-28 md:w-40 h-auto mx-auto"
              />
            </div>
            <div className="hidden md:flex flex flex-row flex-wrap justify-center items-center md:px-8 pb-8 md:pb-0 space-y-4 space-x-4 md:space-x-6 lg:space-x-6">
              <img
                src="./assets/AMI Entertainment.png"
                alt="AMI Entertainment"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Fizy.png"
                alt="Fizy"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/FLO.png"
                alt="FLO"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/genie.png"
                alt="Genie"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/iHeart Radio.png"
                alt="iHeart Radio"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/IIM.png"
                alt="IIM"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/JAXSTA.webp"
                alt="JAXSTA"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Kantar.png"
                alt="Kantar"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/RX Music.png"
                alt="RX Music"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/SiriusXM.jpg"
                alt="SiriusXM"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Soundcloud.jpeg"
                alt="Soundcloud"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/SoundExchange.png"
                alt="SoundExchange"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Stellar Entertainment.jpeg"
                alt="Stellar Entertainment"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Tidal.png"
                alt="Tidal"
                className=" bg-white w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Tiktok.png"
                alt="Tiktok"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/TiVo.png"
                alt="TiVo"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Trebel.png"
                alt="Trebel"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Triller.png"
                alt="Triller"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Tuned Global.jpeg"
                alt="Tuned Global"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/ververlife.png"
                alt="VerveLife"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/yango play.png"
                alt="Yango Play"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/7 Digital.png"
                alt="7 Digital"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Anghami.png"
                alt="Anghami"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Audible Magic.png"
                alt="Audible Magic"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Audiomack.png"
                alt="Audiomack"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Boomplay.png"
                alt="Boomplay"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Bugs.webp"
                alt="Bugs"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Claro Musica.png"
                alt="Claro Musica"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Deezer.png"
                alt="Deezer"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Gaana.png"
                alt="Gaana"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/JOOX.png"
                alt="JOOX"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/KKBOX.jpg"
                alt="KKBOX"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Kuack Media Group.jpeg"
                alt="Kuack Media Group"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/KuGou(tencent).png"
                alt="KuGou (Tencent)"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/MediaNet.jpeg"
                alt="MediaNet"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Melon.webp"
                alt="Melon"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/MOOV.jpg"
                alt="MOOV"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/muud.png"
                alt="Muud"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/NCT (NhacCuaTui).jpg"
                alt="NCT (NhacCuaTui)"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Pandora.png"
                alt="Pandora"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Peloton.jpg"
                alt="Peloton"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Qobuz.png"
                alt="Qobuz"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/QQ_Music.svg"
                alt="QQ Music"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/TouchTunes.jpeg"
                alt="TouchTunes"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Vera.png"
                alt="Vera"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/VIBE_logo.png"
                alt="VIBE"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
              <img
                src="./assets/Zing mp3.png"
                alt="Zing MP3"
                className="w-12 sm:w-20 md:w-20 h-auto"
              />
            </div>
            {/* Remaining icons (Hidden until 'showAll' is true) */}
            {showAll && (
              <div className="md:hidden flex flex-row flex-wrap justify-center items-center px-3 md:px-12 pb-8 md:pb-0 space-y-4 space-x-4 md:space-x-6 lg:space-x-6">
                <img
                  src="./assets/AMI Entertainment.png"
                  alt="AMI Entertainment"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Fizy.png"
                  alt="Fizy"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/FLO.png"
                  alt="FLO"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/genie.png"
                  alt="Genie"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/iHeart Radio.png"
                  alt="iHeart Radio"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/IIM.png"
                  alt="IIM"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/JAXSTA.webp"
                  alt="JAXSTA"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Kantar.png"
                  alt="Kantar"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/RX Music.png"
                  alt="RX Music"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/SiriusXM.jpg"
                  alt="SiriusXM"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Soundcloud.jpeg"
                  alt="Soundcloud"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/SoundExchange.png"
                  alt="SoundExchange"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Stellar Entertainment.jpeg"
                  alt="Stellar Entertainment"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Tidal.png"
                  alt="Tidal"
                  className=" bg-white w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Tiktok.png"
                  alt="Tiktok"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/TiVo.png"
                  alt="TiVo"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Trebel.png"
                  alt="Trebel"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Triller.png"
                  alt="Triller"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Tuned Global.jpeg"
                  alt="Tuned Global"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/ververlife.png"
                  alt="VerveLife"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/yango play.png"
                  alt="Yango Play"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/7 Digital.png"
                  alt="7 Digital"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Anghami.png"
                  alt="Anghami"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Audible Magic.png"
                  alt="Audible Magic"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Audiomack.png"
                  alt="Audiomack"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Boomplay.png"
                  alt="Boomplay"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Bugs.webp"
                  alt="Bugs"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Claro Musica.png"
                  alt="Claro Musica"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Deezer.png"
                  alt="Deezer"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Gaana.png"
                  alt="Gaana"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/JOOX.png"
                  alt="JOOX"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/KKBOX.jpg"
                  alt="KKBOX"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Kuack Media Group.jpeg"
                  alt="Kuack Media Group"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/KuGou(tencent).png"
                  alt="KuGou (Tencent)"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/MediaNet.jpeg"
                  alt="MediaNet"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Melon.webp"
                  alt="Melon"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/MOOV.jpg"
                  alt="MOOV"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/muud.png"
                  alt="Muud"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/NCT (NhacCuaTui).jpg"
                  alt="NCT (NhacCuaTui)"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Pandora.png"
                  alt="Pandora"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Peloton.jpg"
                  alt="Peloton"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Qobuz.png"
                  alt="Qobuz"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/QQ_Music.svg"
                  alt="QQ Music"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/TouchTunes.jpeg"
                  alt="TouchTunes"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Vera.png"
                  alt="Vera"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/VIBE_logo.png"
                  alt="VIBE"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
                <img
                  src="./assets/Zing mp3.png"
                  alt="Zing MP3"
                  className="w-12 sm:w-20 md:w-20 h-auto"
                />
              </div>
            )}
          </div>
          {/* Show More / Show Less Button */}
          <button
            className="text-white text-lg md:hidden font-normal mt-4"
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? "Show Less" : "Show All"}
          </button>
          {/* "+ more" Text for Desktop */}
          <span className="hidden md:inline text-white text-2xl font-normal mt-4">
            + more
          </span>
        </div>
      </section>

      <section className="bg-[#FEC961]">
        <div className="flex flex-col md:flex-row justify-center items-center py-8 md:py-16 lg:py-32 px-4 md:px-10 lg:px-20 xl:px-40">
          <img
            src="assets/artist-pic.png"
            alt="artist-pic"
            className="w-64 md:w-auto h-auto mb-8 md:mb-0"
          />
          <div className="md:pl-16 md:pr-40">
            <h1 className="text-lg md:text-2xl lg:text-3xl font-bold pb-4 md:pb-8">
              Let us help you reach your music to the world.
            </h1>
            <p className="text-lg md:text-2xl font-normal break-words pb-8 md:pb-16">
              Get your music playing over various digital stores and streaming
              services worldwide.
            </p>
            <Link to={publicRouteLink.SIGNIN}>
              <button className="text-white bg-[#FF6C2F] w-full md:w-80 h-20 rounded-full">
                <span className="text-lg md:text-2xl font-medium break-words">
                  Artist Dashboard
                </span>
              </button>
            </Link>
          </div>
        </div>
      </section>

      <section className="bg-gradient-to-t from-[#FEC961] to-black min-h-screen">
        <div className="pt-16 md:pt-20 lg:pt-32 flex justify-center">
          <h1 className="text-white text-4xl md:text-6xl lg:text-7xl font-bold break-words text-center max-w-3xl">
            Want to get started?
          </h1>
        </div>

        <div className="pt-8 md:pt-12 lg:pt-16 container mx-auto text-center px-4">
          <p className="text-white text-lg md:text-3xl lg:text-4xl break-words text-center items-center ">
            Welcome to the B2B website of Hungama Artist Aloud! This is the
            place where YOU as an artist can share with us your creativity for
            Acquisition and Distribution.
          </p>
        </div>

        <div className="py-8 md:py-24 lg:py-32 px-6 md:px-0 flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-8 lg:space-x-12">
          <div className="w-80 md:w-96 h-40 flex justify-center items-center bg-[#474135] rounded-3xl">
            <span className="text-white text-xl md:text-3xl font-medium">
              Create Music
            </span>
          </div>
          <div className="w-80 md:w-96 h-40 flex justify-center items-center bg-[#474135] rounded-3xl">
            <span className="text-white text-xl md:text-3xl font-medium">
              Distribute worldwide
            </span>
          </div>
          <div className="w-80 md:w-96 h-40 flex justify-center items-center bg-[#474135] rounded-3xl">
            <span className="text-white text-xl md:text-3xl font-medium">
              Start Earning
            </span>
          </div>
        </div>
      </section>

      <section className="bg-black text-white px-4 md:px-12 lg:px-20">
        <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold break-words text-center py-6 md:py-12 lg:py-16 mb-4">
          What you can expect
        </h1>

        <div className="flex flex-col md:flex-row justify-center items-stretch gap-0 md:gap-12 lg:gap-16 pb-12 md:pb-16 lg:pb-20 mx-auto">
          {[
            {
              title: "Targeted Promotion",
              text: "Get your music in front of the right audience, ensuring it reaches those who'll appreciate it the most.",
            },
            {
              title: "Social Media Mastery",
              text: "Harness the power of social media with engaging content and strategies that boost your online presence.",
            },
            {
              title: "Fan Engagement",
              text: "Build a loyal fan base through meaningful connections and interactions.",
            },
            {
              title: "Data-Driven Insights",
              text: "Stay informed with data analytics that help you make informed decisions for your artist career.",
            },
          ].map((item, index) => (
            <div
              key={index}
              className="w-full md:w-1/4 border-0 md:border-2 border-[#FEC961] p-4 rounded-lg flex flex-col justify-between h-auto sm:min-h-[300px] mb-4"
            >
              <h3 className="text-2xl text-bold text-[#FEC961] mb-4 text-center">
                {item.title}
              </h3>
              <p className="text-lg text-center">{item.text}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="bg-gradient-to-t from-[#FEC961] to-black text-white pt-10">
        <h1 className="text-4xl md:text-6xl font-bold text-center">
          Artist Review
        </h1>
        <div className="flex justify-center">
          <div className="pt-8 pb-16 md:pt-16 md:pb-32 w-full md:w-3/4 px-4">
            <Slider {...settings}>
              {data.map((item, index) => (
                <div key={index}>
                  <div className="bg-black flex flex-col md:flex-row items-center px-6 md:px-16 py-8 md:py-0 h-auto md:h-80 rounded-2xl relative space-y-6 md:space-y-0">
                    {/* Image Section */}
                    <div className="w-full md:w-2/5 flex justify-center items-center">
                      <img
                        src={item.img}
                        alt={item.name}
                        className="rounded-full w-28 h-28 md:w-auto md:h-auto"
                      />
                    </div>
                    {/* Text Section */}
                    <div className="flex flex-col justify-start items-start w-full md:w-3/5 space-y-4">
                      <h2 className="text-xl md:text-2xl font-medium break-words">
                        {item.name}
                      </h2>
                      <img
                        src="assets/quote.png"
                        alt="quotes"
                        className="w-6 md:w-auto"
                      />
                      <p className="text-sm md:text-lg font-medium">
                        {item.bio}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default LandingPage;
