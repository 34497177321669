import React from 'react'

// const WithWrapper = ({ children, className = 'h-screen bg-[#1A1A1A]' }) => {
const WithWrapper = ({ children, className = 'bg-[#1A1A1A]' }) => {
    return (
        <div className={className}>
            {children}
        </div>
    )
}

export default WithWrapper
