import React from "react";
import { useState, useEffect } from "react";
import { publicRouteLink } from "../constant/Routes";
import TermsCheckbox from "./TermsCheckbox";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MainContext } from "../context/main";
import { useContext } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

const SignUp1 = ({ onClose }) => {
  const [ph, setPh] = useState("");
  const [isValidEmail, setValidEmail] = useState(true);
  const [userEmail, setUserEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isPhoneExists, setIsPhoneExists] = useState(false);
  const { formData, handleFormData } = useContext(MainContext);
  const {
    countryCode,
    setCountryCode,
    mob,
    setMob,
    buttonName,
    buttonValue,
    updateButtonInfo,
    email,
    setEmail,
    setSignUpType,
  } = useContext(MainContext);
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState("");
  const navigate = useNavigate();

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  useEffect(() => {
    console.log("FormData Updated:", formData);
  }, [formData]);

  // Function to send OTP after phone verification
  // const sendOTP = async () => {
  //   onClose && onClose();
  //   updateButtonInfo("Send OTP", "SignUp");

  //   console.log("Mob:", mob, "Country Code:", countryCode);
  //   try {
  //     if (!formData?.phone) {
  //       toast.error("Please enter your number");
  //       return;
  //     }
  //     const data = {
  //       email:email,
  //       mob: mob,
  //       countryCode: countryCode,
  //     };
  //     formData.email = data.email;
  //     formData.phone = data.mob;
  //     formData.country_code = data.countryCode;

  //     console.log(formData);

  //     const resp = await fetch(
  //       "https://haartistaloud-backend.kollywoodhungama.com/v2/send-otp",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(data),
  //       }
  //     );

  //     if (!resp.ok) {
  //       const errorData = await resp
  //         .json()
  //         .catch(() => ({ error: "Invalid response format" }));
  //       console.error(
  //         "Failed to send OTP:",
  //         resp.status,
  //         resp.statusText,
  //         errorData
  //       );
  //       toast.error("OTP not sent. Please try again!");
  //       return;
  //     }

  //     const respData = await resp.json();
  //     console.log("Response from send OTP API:", respData);

  //     if (respData.result.statusCode === 200) {
  //       toast.success("OTP sent successfully!");
  //       navigate(publicRouteLink.OTP_VERIFICATION);
  //     } else {
  //       toast.error("OTP not sent. Please try again!");
  //     }
  //   } catch (error) {
  //     console.error("Send OTP Error:", error);
  //     toast.error("An error occurred. Please try again.");
  //   }
  // };

  const sendOTP = async (isPhoneSignUp) => {
    onClose && onClose();
    updateButtonInfo("Send OTP", "SignUp");

    console.log("Mob:", mob, "Country Code:", countryCode, "Email:", email);

    try {
      // Validation for phone or email based on user choice
      if (isPhoneSignUp) {
        if (!mob) {
          toast.error("Please enter your number");
          return;
        }
      } else {
        if (!email) {
          toast.error("Please enter your email");
          return;
        }
      }

      // Create the data payload dynamically based on the user's choice
      const data = isPhoneSignUp
        ? {
            email: "",
            mob: mob,
            countryCode: countryCode,
          }
        : {
            email: email,
            mob: "",
            countryCode: countryCode,
          };

      console.log("Data sent to API:", data);

      const resp = await fetch(
        "https://haartistaloud-backend.kollywoodhungama.com/v2/send-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!resp.ok) {
        const errorData = await resp
          .json()
          .catch(() => ({ error: "Invalid response format" }));
        console.error(
          "Failed to send OTP:",
          resp.status,
          resp.statusText,
          errorData
        );
        toast.error("OTP not sent. Please try again!");
        return;
      }

      const respData = await resp.json();
      console.log("Response from send OTP API:", respData);

      if (respData.result.statusCode === 200) {
        toast.success("OTP sent successfully!");
        navigate(publicRouteLink.OTP_VERIFICATION);
      } else {
        toast.error("OTP not sent. Please try again!");
      }
    } catch (error) {
      console.error("Send OTP Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };
  // Function to check if the phone number exists
  const checkPhoneNumber = async () => {
    if (!isChecked) {
      toast.error("Please agree to the Terms & Conditions.");
      return;
    }
    if (!mob) {
      toast.error("Please enter a valid phone number.");
      return;
    }

    try {
      const response = await fetch(
        `https://haartistaloud-backend.kollywoodhungama.com/v1/CheckPhoneNo/${mob}`
      );

      // if (!response.ok) {
      //   const errorDetails = await response.text();
      //   console.error("Phone check error:", errorDetails);
      //   toast.error("Phone number check failed. Please try again.");
      //   return;
      // }

      const data = await response.json();
      if (data.status === 200) {
        const isEmailExists = await checkEmailForPhone();
        if (isEmailExists) {
          setSignUpType("phone");
          sendOTP(true);
        }
      } else if (data.status === 409) {
        toast.error("Phone number already exists. Please log in.");
      } else {
        toast.error("Unexpected phone check response. Try again.");
      }
    } catch (error) {
      console.error("Check Phone Error:", error);
      toast.error("Error checking phone number. Try again later.");
    }
  };

  const checkEmail = async () => {
    if (!isChecked) {
      toast.error("Please agree to the Terms & Conditions.");
      return;
    }
    if (!email) {
      toast.error("Please enter a valid email.");
      return;
    }
    try {
      const response = await fetch(
        `https://haartistaloud-backend.kollywoodhungama.com/v1/checkEmailId/${email}`
      );
      const respData = await response.json();

      if (respData.status === 200) {
        const isPhoneExists = await checkPhoneForEmail();
        if (isPhoneExists) {
          setSignUpType("email");
          sendOTP(false);
        }
      } else if (respData.status === 409) {
        toast.error("Email already exists. Please log in.");
      } else {
        toast.error("Unexpected email response. Try again.");
      }
    } catch (error) {
      console.error("Check Phone Error:", error);
      toast.error("Error checking email. Try again later.");
    }
  };

  const checkEmailForPhone = async () => {
    const emailResponse = await fetch(
      `https://haartistaloud-backend.kollywoodhungama.com/v1/checkEmailId/${email}`
    );
    const emailData = await emailResponse.json();

    if (emailData.status === 200) {
      return true;
    } else if (emailData.status === 409) {
      toast.error("Email already exists. Please log in.");
      return false;
    } else {
      toast.error("Unexpected email response. Try again.");
      return false;
    }
  };

  const checkPhoneForEmail = async () => {
    const phoneResponse = await fetch(
      `https://haartistaloud-backend.kollywoodhungama.com/v1/CheckPhoneNo/${mob}`
    );
    const phoneData = await phoneResponse.json();

    if (phoneData.status === 200) {
      return true;
    } else if (phoneData.status === 409) {
      toast.error("Phone number already exists. Please log in.");
      return false;
    } else {
      toast.error("Unexpected phone check response. Try again.");
      return false;
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setUserEmail(inputEmail);
    setEmail(inputEmail);
    formData.email = inputEmail;
    // Validate the email and set an error message if invalid
    if (inputEmail && validateEmail) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  // Google login and profile fetch handling
  // const handleGoogleLogin = useGoogleLogin({
  //   onSuccess: async (tokenResponse) => {
  //     setUser(tokenResponse);
  //     try {
  //       const res = await axios.get(
  //         "https://www.googleapis.com/oauth2/v1/userinfo",
  //         {
  //           headers: {
  //             Authorization: `Bearer ${tokenResponse.access_token}`,
  //           },
  //         }
  //       );
  //       setProfile(res.data);
  //       navigate("/dashboard");
  //     } catch (error) {
  //       console.error("Google Login Error:", error);
  //       toast.error("Failed to fetch Google profile.");
  //     }
  //   },
  //   onError: (error) => {
  //     console.error("Login Failed:", error);
  //     toast.error("Google login failed. Please try again.");
  //   },
  // });

  // const handleGoogleLogin = useGoogleLogin({
  //   scope: "profile email https://www.googleapis.com/auth/contacts.readonly",
  //   onSuccess: async (tokenResponse) => {
  //     setUser(tokenResponse);
  //     try {
  //       const res = await axios.get(
  //         "https://www.googleapis.com/oauth2/v1/userinfo",
  //         {
  //           headers: {
  //             Authorization: `Bearer ${tokenResponse.access_token}`,
  //           },
  //         }
  //       );

  //       const { name, email } = res.data; // These are included by default in the userinfo endpoint

  //       // To get phone number, you'll need to access the Google People API
  //       const peopleRes = await axios.get(
  //         "https://people.googleapis.com/v1/people/me",
  //         {
  //           headers: {
  //             Authorization: `Bearer ${tokenResponse.access_token}`,
  //           },
  //           params: {
  //             personFields: "phoneNumbers", // Specify to retrieve phone numbers
  //           },
  //         }
  //       );

  //       const phoneNumber = peopleRes.data.phoneNumbers
  //         ? peopleRes.data.phoneNumbers[0].value
  //         : null;

  //       // Log the values to the console for debugging
  //       console.log("Name:", name);
  //       console.log("Email:", email);
  //       console.log("Phone Number:", phoneNumber);

  //       // Set profile data (full name, email, phone number)
  //       setProfile({ name, email, phoneNumber });
  //       navigate("/dashboard");
  //     } catch (error) {
  //       console.error("Google Login Error:", error);
  //       toast.error("Failed to fetch Google profile.");
  //     }
  //   },
  //   onError: (error) => {
  //     console.error("Login Failed:", error);
  //     toast.error("Google login failed. Please try again.");
  //   },
  // });

  // const handleGoogleLogin = useGoogleLogin({
  //   onSuccess: async (tokenResponse) => {
  //     try {
  //       const { access_token } = tokenResponse;

  //       // Fetch user info from Google
  //       const res = await axios.get(
  //         "https://www.googleapis.com/oauth2/v1/userinfo",
  //         {
  //           headers: { Authorization: `Bearer ${access_token}` },
  //         }
  //       );

  //       const userData = res.data;

  //       // Send user data to the backend for account creation/validation
  //       const backendResponse = await axios.post(
  //         "http://localhost:5000/auth/google",
  //         {
  //           token: access_token,
  //           user: userData,
  //         }
  //       );

  //       if (backendResponse.data.success) {
  //         toast.success("Logged in successfully!");
  //         navigate("/dashboard");
  //       } else {
  //         toast.error(backendResponse.data.message || "Login failed");
  //       }
  //     } catch (error) {
  //       console.error("Google Login Error:", error);
  //       toast.error("Login failed. Please try again.");
  //     }
  //   },
  //   onError: (error) => {
  //     console.error("Google Login Failed:", error);
  //     toast.error("Google login failed. Please try again.");
  //   },
  // });

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const { access_token } = tokenResponse;

        if (!access_token) {
          console.error("Access token is missing in Google response.");
          toast.error("Authentication failed. Please try again.");
          return;
        }

        console.log("Google access token received:", access_token);

        // Fetch user info from Google
        const { data: userData } = await axios.get(
          "https://www.googleapis.com/oauth2/v1/userinfo",
          {
            headers: { Authorization: `Bearer ${access_token}` },
          }
        );

        if (!userData || !userData.email) {
          console.error("User data is incomplete:", userData);
          toast.error("Failed to retrieve user information. Please try again.");
          return;
        }

        console.log("Google user data fetched successfully:", userData.email);

        // Send user data to the backend for validation
        const backendResponse = await axios.post(
          "https://haartistaloud-backend.kollywoodhungama.com/auth/google/callback",
          {
            token: access_token,
            user: userData,
          }
        );

        if (backendResponse.data.success) {
          toast.success("Logged in successfully!");
          navigate("/dashboard");
        } else {
          console.error(
            "Backend response error:",
            backendResponse.data.message
          );
          toast.error(
            backendResponse.data.message || "Login failed. Please try again."
          );
        }
      } catch (error) {
        console.error(
          "Google Login Error:",
          error.response?.data || error.message || "Unknown error"
        );
        toast.error("Login failed. Please try again.");
      }
    },
    onError: (error) => {
      console.error("Google Login Failed:", error);
      toast.error("Google login failed. Please try again.");
    },
  });

  useEffect(() => {
    console.log("Updated formData:", formData);
  }, [formData]);

  return (
    // <GoogleOAuthProvider clientId={clientId}>
    <div className="flex items-center justify-center bg-[#1A1A1A] px-16 py-4">
      <form className="bg-[#1A1A1A] text-white rounded-mdF w-96 mb-8">
        <h1 className="text-xl sm:text-xl md:text-3xl lg:text-4xl font-bold text-center">Create your account</h1>
        <p className="mt-6 text-xs sm:text-sm md:text-base lg:text-base">
          Create an account now and be the next sensation in the entertainment
          industry!
        </p>

        <div className="pt-3">
          <label
            className="block text-white text-sm font-normal"
            htmlFor="name"
          >
            Full Name
            <span className="text-[#FF6C2F]">*</span>
          </label>
          <input
            name="fullName"
            className="w-full outline-none bg-white text-black rounded-md pl-2 h-12"
            type="text"
            id="name"
            value={formData?.fullName}
            onChange={handleFormData}
            required
          />

          <label
            className="block text-white text-sm font-normal pt-4"
            htmlFor="ph"
          >
            Mobile Number
            <span className="text-[#FF6C2F]">*</span>
          </label>
          {/* <PhoneInput
            inputProps={{
              name: "phone",
              required: true,
            }}
            inputClass="w-full"
            country={"in"}
            value={ph}
            required
            onChange={(phone, country, e) => {
              let newPh = phone.split(country.dialCode);
              phone = newPh[1];
              setMob(phone);
              setCountryCode(country.dialCode);
              handleFormData(e);
            }}
            className="text-black"
          /> */}
          <PhoneInput
            country={"in"}
            inputProps={{
              name: "phone",
              required: true,
            }}
            value={ph}
            onChange={(phone, country, e) => {
              // console.log("Country Code Before Update:", country.dialCode);
              // console.log("Phone Number Before Update:", phone);

              // Normalize the phone number by removing the country code if it's already included
              let formattedPhone = phone;
              if (phone.startsWith(country.dialCode)) {
                formattedPhone = phone.slice(country.dialCode.length); // Remove the country code part
              }

              // console.log("Formatted Phone Before Update:", formattedPhone); // Log the phone number without country code

              // Set the phone number without country code
              setMob(formattedPhone); // This updates the mob state with the phone number

              // Ensure we set the country code with the "+" prefix if it's not already there
              const updatedCountryCode = country.dialCode.startsWith("+")
                ? country.dialCode
                : `+${country.dialCode}`;
              console.log("Updated Country Code:", updatedCountryCode); // Log the final country code

              setCountryCode(updatedCountryCode); // This will store the country code correctly with one "+"

              // Update the form data state
              handleFormData({
                target: { name: "phone", value: formattedPhone },
              }); // Update the phone number in formData
              handleFormData({
                target: { name: "country_code", value: updatedCountryCode },
              }); // Update the country code in formData

              // console.log("Updated FormData after phone input:", formData); // Log the updated formData
            }}
            className="text-black"
          />

          <label
            className="block text-white text-sm font-normal pt-4"
            htmlFor="email"
          >
            Email Address
            <span className="text-[#FF6C2F]">*</span>
          </label>
          <input
            name="email"
            className="w-full outline-none bg-white text-black rounded-md pl-2 h-12"
            type="email"
            id="email"
            value={userEmail}
            onChange={handleEmailChange}
            required
          />
          {!emailError && (
            <p className="text-red-500 text-sm mt-1">{emailError}</p>
          )}
          <TermsCheckbox isChecked={isChecked} setIsChecked={setIsChecked} />

          <div className="w-full">
            <button
              className="w-full sm:w-2/5 bg-[#FF6C2F] text-white h-12 rounded-md text-sm font-medium mt-3 ml-0 sm:ml-6"
              id="sign-in-button"
              type="button"
              onClick={checkPhoneNumber}
              // onClick={sendOTP}
            >
              Sign Up with Phone
            </button>
            <button
              className="w-full sm:w-2/5 bg-[#FF6C2F] text-white h-12 rounded-md text-sm font-medium mt-3 ml-0 sm:ml-6"
              id="sign-in-button"
              type="button"
              onClick={checkEmail}
              // onClick={sendOTP}
            >
              Sign Up with Email
            </button>
          </div>
          {isPhoneExists && (
            <p className="text-[#FA3235]">
              The user account already exists; please sign in.
            </p>
          )}

          <div className="my-1 text-center">or</div>
          <button
            className="w-full bg-[#262626] text-white h-12 rounded-md text-sm font-medium mt-3 opacity-20 cursor-not-allowed"
            type="button"
            // onClick={handleGoogleLogin}
          >
            <span
              className="flex flex-row justify-center items-center "
              style={{ pointerEvents: "none" }}
            >
              <img
                src="assets\google-icon.svg"
                alt="google-icon"
                className="pr-2"
              />
              Sign up with Google
            </span>
          </button>

          <div className="flex flex-col sm:flex-row justify-between items-center mt-5 sm:space-x-4 space-y-3 sm:space-y-0">
            <p className="items-start">Already have an account?</p>
            <a
              className="bg-[#262626] text-[#FF6C2F] w-28 h-9 flex items-center justify-center rounded-md"
              href={publicRouteLink.SIGNIN}
            >
              Login Now
            </a>
          </div>
        </div>
      </form>
    </div>
    // </GoogleOAuthProvider>
  );
};

export default SignUp1;
