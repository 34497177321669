import React, { useState, useEffect } from "react";
import { publicRouteLink } from "../constant/Routes";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useContext } from "react";
import { MainContext } from "../context/main";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

const SignInForm = ({ onClose }) => {
  const [ph, setPh] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isPhoneExists, setIsPhoneExists] = useState(true);
  const [isEmailExists, setIsEmailExists] = useState(true);
  const {
    countryCode,
    setCountryCode,
    mob,
    setMob,
    updateButtonInfo,
    email,
    setEmail,
    setSignUpType,
  } = useContext(MainContext);
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState("");
  const navigate = useNavigate();

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Handle email input change
  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setUserEmail(inputEmail);

    // Validate the email and set an error message if invalid
    if (inputEmail && !validateEmail(inputEmail)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
      setEmail(inputEmail);
    }
  };

  const handleClick = async (event) => {
    if (email) {
      await checkEmail(); // Check phone number first
    } else {
      await checkPhoneNumber();
    }
  };

  // Function to check if phone number exists in the database
  const checkPhoneNumber = async () => {
    try {
      const response = await fetch(
        `https://haartistaloud-backend.kollywoodhungama.com/v1/CheckPhoneNo/${mob}`
      );
      const respData = await response.json();

      // If the phone number exists (status 200), proceed with OTP
      if (respData.status === 409) {
        setSignUpType("phone");
        await sendOTP();
      }
      // If the phone number does not exist (status 200 but not registered), show sign up message
      else if (respData.status === 200) {
        setIsPhoneExists(false); // Set flag for no account
        toast.error("Phone number not registered. Please Sign Up!");
      }
    } catch (err) {
      toast.error("Something went wrong. Please try again!");
      console.error("Error from checkPhoneNumber function:", err.message);
    }
  };

  const checkEmail = async () => {
    console.log("Email:", email);
    try {
      const response = await fetch(
        `https://haartistaloud-backend.kollywoodhungama.com/v1/checkEmailId/${email}`
      );
      const respData = await response.json();

      // If the phone number exists (status 200), proceed with OTP
      if (respData.status === 409) {
        setSignUpType("email");
        await sendOTP();
      }
      // If the phone number does not exist (status 200 but not registered), show sign up message
      else if (respData.status === 200) {
        setIsPhoneExists(false); // Set flag for no account
        toast.error("Email not registered. Please Sign Up!");
      }
    } catch (err) {
      toast.error("Something went wrong. Please try again!");
      console.error("Error from checkEmail function:", err.message);
    }
  };

  // Handle phone number change from PhoneInput component
  // const handlePhChange = (phone, country) => {
  //   let newPh = phone.split(country.dialCode);
  //   newPh = newPh[1];
  //   setPh(phone);
  //   setMob(newPh);  // Update mobile number in context
  //   setCountryCode(country.dialCode);  // Set country code
  // };

  const handlePhChange = (phone, country) => {
    let newPh;

    // Check if the number starts with '91' (without a '+')
    if (phone.startsWith("91") && !phone.startsWith("+91")) {
      console.log("Detected number starting with '91' without country code");
      newPh = phone.slice(2); // Remove the leading '91'
    } else {
      // Default logic to split by country code
      newPh = phone.split(country.dialCode)[1] || "";
    }

    newPh = newPh.replace(/\D/g, ""); // Remove any non-numeric characters
    // console.log("Processed Phone Number:", newPh);

    // Update state
    setPh(phone);
    setMob(newPh);
    setCountryCode(country.dialCode);
  };

  // Send OTP after checking phone number
  const sendOTP = async () => {
    onClose && onClose();
    updateButtonInfo("Send OTP", "SignIn");

    try {
      const url =
        "https://haartistaloud-backend.kollywoodhungama.com/v2/send-otp";

      const resp = await axios({
        url: url,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        data: {
          email: email,
          mob: mob,
          countryCode: "+" + countryCode,
        },
      });

      // Log the response for debugging purposes
      console.log("OTP Response:", JSON.stringify(resp.data, null, 2));

      // Check if the response indicates success
      if (resp.data.message === "OTP Sent Successfully") {
        toast.success("OTP sent successfully!");
        navigate(publicRouteLink.OTP_VERIFICATION); // Redirect to OTP verification page
      } else {
        toast.error(
          `OTP not sent. Error: ${resp.data.message || "Unknown error"}`
        );
      }
    } catch (error) {
      console.log("Error from sendOTP function:", error);
      toast.error(`OTP request failed: ${error.message || "Unknown error"}`);
    }
  };

  // const login = useGoogleLogin({
  //   onSuccess: (codeResponse) => {
  //     setUser(codeResponse);
  //     navigate(publicRouteLink.DASHBOARD);
  //     console.log(codeResponse);
  //   },
  //   onError: (error) => console.log("Login Failed:", error),
  // });

  // const handleGoogleLogin = useGoogleLogin({
  //   onSuccess: async (tokenResponse) => {
  //     try {
  //       const { access_token } = tokenResponse;

  //       // Fetch user info from Google
  //       const res = await axios.get(
  //         "https://www.googleapis.com/oauth2/v1/userinfo",
  //         {
  //           headers: { Authorization: `Bearer ${access_token}` },
  //         }
  //       );

  //       const userData = res.data;

  //       // Send user data to the backend for account creation/validation
  //       const backendResponse = await axios.post(
  //         "http://localhost:5000/auth/google",
  //         {
  //           token: access_token,
  //           user: userData,
  //         }
  //       );

  //       if (backendResponse.data.success) {
  //         toast.success("Logged in successfully!");
  //         navigate("/dashboard");
  //       } else {
  //         toast.error(backendResponse.data.message || "Login failed");
  //       }
  //     } catch (error) {
  //       console.error("Google Login Error:", error);
  //       toast.error("Login failed. Please try again.");
  //     }
  //   },
  //   onError: (error) => {
  //     console.error("Google Login Failed:", error);
  //     toast.error("Google login failed. Please try again.");
  //   },
  // });

  // useEffect(() => {
  //   console.log(process.env);
  //   if (user) {
  //     axios
  //       .get(
  //         `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${user.access_token}`,
  //             Accept: "application/json",
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         setProfile(res.data);
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }, [user]);

  const handleGoogleSignIn = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const { access_token } = tokenResponse;

        if (!access_token) {
          console.error("Access token is missing in Google response.");
          toast.error("Authentication failed. Please try again.");
          return;
        }

        console.log("Google access token received for sign-in:", access_token);

        // Fetch user info from Google
        const { data: userData } = await axios.get(
          "https://www.googleapis.com/oauth2/v1/userinfo",
          {
            headers: { Authorization: `Bearer ${access_token}` },
          }
        );

        if (!userData || !userData.email) {
          console.error("User data is incomplete during sign-in:", userData);
          toast.error("Failed to retrieve user information. Please try again.");
          return;
        }

        console.log(
          "Google user data fetched successfully for sign-in:",
          userData.email
        );

        // Send user data to the backend for sign-in validation
        const backendResponse = await axios.post(
          "https://haartistaloud-backend.kollywoodhungama.com/auth/google/callback",
          {
            token: access_token,
            user: userData,
          }
        );

        if (backendResponse.data.success) {
          toast.success("Logged in successfully!");
          navigate("/dashboard");
        } else {
          console.error(
            "Backend response error during sign-in:",
            backendResponse.data.message
          );
          toast.error(
            backendResponse.data.message || "Login failed. Please try again."
          );
        }
      } catch (error) {
        console.error(
          "Google Sign-In Error:",
          error.response?.data || error.message || "Unknown error"
        );
        toast.error("Login failed. Please try again.");
      }
    },
    onError: (error) => {
      console.error("Google Sign-In Failed:", error);
      toast.error("Google sign-in failed. Please try again.");
    },
  });

  return (
    // <GoogleOAuthProvider clientId={clientId}>
    <div className="flex items-center justify-center bg-[#1A1A1A] px-16 py-12">
      <div className="bg-[#1A1A1A] text-white rounded-md w-96">
        <h1 className="text-xl sm:text-xl md:text-3xl lg:text-4xl font-bold text-center">
          Sign in to your account
        </h1>
        <div className="pt-5">
          <label
            className="block text-white text-sm font-medium"
            htmlFor="phoneNumber"
          >
            Mobile Number
          </label>
          <div className="flex items-center">
            <PhoneInput
              country={"in"}
              value={ph}
              onChange={handlePhChange}
              inputClass="w-full pl-12 text-black"
              buttonClass="absolute"
              containerClass="relative w-full"
              disabled={!!userEmail}
            />
          </div>
        </div>
        <div className="text-center pt-4 ">or</div>
        <div className="">
          <label
            className="block text-white text-sm font-medium"
            htmlFor="phoneNumber"
          >
            Email Address
          </label>
          <div className="flex items-center relative">
            <input
              type="email"
              value={userEmail}
              onChange={handleEmailChange}
              placeholder="Enter your email"
              className={`w-full pl-12 pr-4 py-3 text-black border rounded-md outline-none ${
                emailError ? "border-red-500" : "border-gray-300"
              }`}
              disabled={!!ph}
            />
            <div className="absolute left-3 top-2/4 transform -translate-y-2/4 text-gray-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2.94 6.29A2.49 2.49 0 002 8.6v4.8c0 1.2 1.3 2 2.3 1.6l5.3-2.4 5.3 2.4c1 .4 2.3-.4 2.3-1.6V8.6a2.5 2.5 0 00-.94-2.31L10 2 2.94 6.29zM10 3.8l5.1 2.3L10 8.4 4.9 6.1 10 3.8z" />
              </svg>
            </div>
          </div>
          {emailError && (
            <p className="text-red-500 text-sm mt-1">{emailError}</p>
          )}
        </div>
        <button
          className="w-full bg-[#FF6C2F] text-white h-12 rounded-md text-sm font-medium mt-3"
          type="submit"
          onClick={handleClick}
        >
          Send OTP
        </button>

        <div className="my-3 text-center">or</div>
        <button
          className="w-full bg-[#262626] text-white h-12 rounded-md text-sm font-medium mt-3 opacity-20 cursor-not-allowed"
          type="button"
          // onClick={handleGoogleSignIn}
        >
          <span className="flex flex-row justify-center items-center">
            <img
              src="assets/google-icon.svg"
              alt="google-icon"
              className="pr-2"
            />
            Sign in with Google
          </span>
        </button>

        {!isPhoneExists && (
          <p className="text-[#FA3235]">
            You don't have an account. Please create an account!
          </p>
        )}

        <div className="flex flex-col sm:flex-row items-center justify-end mt-10 sm:space-x-4 space-y-3 sm:space-y-0">
          <p>Don’t have an account?</p>
          <a
            className="bg-[#262626] text-[#FF6C2F] w-28 h-9 flex items-center justify-center rounded-md"
            href={publicRouteLink.SIGNUP}
          >
            Create Now
          </a>
        </div>
      </div>
    </div>
    // </GoogleOAuthProvider>
  );
};

export default SignInForm;
