import React from "react";

const Ticket = () => {
  const handleBookNowClick = () => {
    if (window.fbq) {
      window.fbq('track', 'addToCart'); // Track the "Lead" event or a custom event
    }
    window.location.href =
      "https://www.skillboxes.com/events/ticket/lifafa-live-at-sunburn-unioin";
  };
  return (
    <div className="flex flex-col items-center bg-black pb-6">
      {/* Header with logo */}
      <header className="w-full flex justify-start mb-4 bg-black">
        <img
          src="\assets\HungamaLogo.png" // Replace with your logo path
          alt="Logo"
          className="h-16 w-auto p-2"
        />
        {/* Event Date & Location */}
        <div className="flex items-center text-white">
          <p className="ml-4 mr-4 text-l font-semibold">21 Feb</p>
          <div className="flex items-center">
            {/* Location Icon (SVG) */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
              />
            </svg>

            <p className="text-l font-semibold">Bengaluru</p>
          </div>
        </div>
      </header>

      {/* Banner */}
      <div
        className="w-full max-w-[90%] md:max-w-[1080px] aspect-[1080/1350] mx-4 my-4 md:mx-auto bg-cover bg-center"
        style={{ backgroundImage: "url('/assets/Lifafa_Sunburn_Union.png')" }}
      >
        {/* Banner content or styling */}
      </div>

      {/* Book Now Button */}
      {/* Book Now Button */}
      <button
        className="w-full max-w-[90%] md:max-w-[400px] py-3 px-6 bg-[#FF6C2F] text-white font-semibold text-xl rounded-lg hover:bg-[#E65B26] active:bg-[#CC4F20] transition-all mx-4 mt-2 md:mx-auto"
        onClick={handleBookNowClick}
      >
        Book Now
      </button>

      {/* Text Description Box */}
      <div className="w-full max-w-[90%] md:max-w-[800px] mt-6 bg-white py-4 px-8 rounded-lg mx-4 md:mx-auto">
        <p className="text-black font-semibold py-4 text-xl">Description:</p>
        <p className="text-black">
          Get ready to experience @lifafa like never before! 🎶✨ Bringing his
          unique take on Indian Pop to Sunburn Union, Bengaluru, on 21st
          February, Lifafa’s music is a fusion of deep emotions, experimental
          sounds, and irresistible rhythms.
        </p>
        <p className="text-black py-2">
          From soulful melodies to groovy beats, his performance promises to be
          an unforgettable journey through sound and storytelling. Don’t miss
          out on this one-of-a-kind musical experience!
        </p>
        <p className="text-black py-2">
          🎟 Tickets available exclusively on Skillbox – book yours now!
        </p>
        <p className="text-black py-2">
          #SunburnUnion #LifafaLive #IndianPop #GrooveWithLifafa
        </p>
      </div>
    </div>
  );
};

export default Ticket;
