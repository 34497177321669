import React from "react";

const AboutUs = () => {
  return (
    <div className="w-full bg-[#1A1A1A] py-16 px-6 md:px-20">
      <h1 className="lg:pb-12 md:pb-8 sm:pb-6 text-2xl md:text-[33px] text-white font-medium text-center md:text-left">
        About <span className="text-[#FEC961]">Us</span>
      </h1>
      <div className="flex flex-col-reverse md:flex-row items-center md:items-start">
        <div className="w-full md:w-3/5 flex items-center p-6 md:px-20 bg-[#00000040] rounded-lg h-auto md:h-[400px] lg:h-[500px]">
          <div className="text-base text-white font-medium w-full">
            <p className="text-center md:text-left">
              Welcome to the B2C website of{" "}
              <span className="text-[#FF6C2F]">Hungama Artist Aloud!</span> This
              is the place where YOU as an artist can share with us your
              creativity for Acquisition and Distribution.
            </p>
            <p className="pt-5 text-center md:text-left">
              <span className="text-[#FF6C2F]">Hungama Artist Aloud</span> is a
              talent and independent content platform for both Independent
              content makers and fans. We promote Musicians, Bands, Singers,
              Composers, Instrumentalists, Comedians, Dancers, etc., who create
              commercial non-film content and help them distribute their
              original content. Our B2C platform, www.HungamaArtistAloud.com,
              offers a diverse library of Audio and Video content suitable for
              various tastes.
            </p>
            <p className="pt-5 text-center md:text-left">
              We work with both established and upcoming talent, putting them in
              the spotlight with innovative music and entertainment content
              formats. Our dedicated team follows the philosophy of 'Talent
              First'.
            </p>
          </div>
        </div>
        <div className="w-full md:w-2/5 h-64 md:h-[500px] relative mt-8 md:mt-0">
          <img
            src="assets/Aboutus-bg.png"
            alt=""
            className="w-full h-full object-cover"
          />
          <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center">
            <img
              src="assets/logo-img.png"
              alt="Logo"
              className="w-2/3 h-2/3 object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
